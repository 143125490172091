
.action{

    $this:&;

    &:hover{

        #{$this}__info{

            opacity: 1;
            color: #fff;

        }

    }

    &_fix-margin{

        margin-bottom: -20px;

    }

    &-2{

        #{$this}__info{

            padding: 20px;
        
        }

    }

    &__cup{

        margin: auto;
        width: fit-content;

    }

    .bg{

        display: flex;
        background-color: #F1F7FA;

    }

}

.action-1{

    $this: &;

    height: 314px;
    flex: 0 0 calc(50% - #{$gap * 2});
    position: relative;
    color: #ffffff;
    border-radius: $radius;
    overflow: hidden;

    margin-bottom: 32px;

    &__content{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 35px 35px 49px;
        opacity: 0;
        transition: opacity $tr-time;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: var(--main);
            opacity: 0.92;
        }
    }
    &__date{
        font-size: calcFluid(10,14);
        line-height: 1;
    }
    &__title{
        margin-bottom: 10px;
        font-size: calcFluid(16,20);
        line-height: calcFluid(22,26);
        font-weight: 700;
    }
    &__text{
        max-width: 350px;
        width: 100%;
        font-size: calcFluid(13,16);
        line-height: calcFluid(19,24);
    }
    &__date, &__title, &__text, &__btn{
        display: block;
        position: relative;
        z-index: 1;
    }
    &__btn{
        display: inline-flex;
        padding: 0 43px;
    }
    &__bg{
        border-radius: $radius;
        overflow: hidden;
    }
    &:hover{
        color: #fff;
        #{$this}__content{
            opacity: 1;
        }
    }
}

@media (max-width: 1199px){
    .action-1{
        height: auto;
        margin-bottom: 14px;
        $this: &;
        &__bg{
            position: static;
            .bg__img{
                height: auto;
            }
        }
        &__content{
            position: static;
            display: none;
            padding: 27px 23px;
        }
        &__date{
            margin-bottom: 19px;
        }
        &__title{
            font-weight: 500;
        }
        &__text{
            margin-bottom: 20px;
            max-width: unset;
        }
        &__btn{
            width: 100%;
            padding: 0;
        }

        &:hover{
            #{$this}__bg{
                position: absolute;
            }
            #{$this}__content{
                display: block;
            }
        }

    }
}
@media (max-width: 575px){
    .action-1{
        flex-basis: calc(100% - #{$gap * 2});
    }
}