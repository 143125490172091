.header{
	position: sticky;
	top: 0;
	left: 0;
	z-index: 5;
	background-color: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	&__delimeter{
		border-bottom: 1px solid #EAEAEA;
	}
	&__slogan{
		font-size: 12px;
		line-height: 1.33;
		color: #b6b6b6;
		&--offset-left{
			margin-left: 37px;
		}
	}
	&__logo{
		display: flex;
		max-width: 153px;
		max-height: 38px;	
		&-icon{
			object-fit: contain;
			max-height: 100%;
			max-width: 100%;
		}
	}
	&__contacts-right{
		margin-left: auto;
	}
	&__mail{
		&-icon{
			width: 14px;
			height: 12px;
		}
		&-value{
			vertical-align: middle;
			font-size: 14px;
			line-height: 1.5;
			text-decoration: underline;
		}
	}
	&__tel{
		&-icon{
			width: 12px;
			height: 12px;
		}
		&-value{
			vertical-align: middle;
			font-size: 15px;
			line-height: 1.5;
			font-weight: 700;
		}
	}
	&__contact{
		$this: &;
		&-icon{
			margin-right: 9px;
			fill: $text;
		}
		&:hover{
			#{$this}-icon{
				fill: var(--main);
			}
		}
		&:not(:first-child){
			margin-left: 32px;
		}
	}
	&__bottom{
		padding: 15px 0;
	}
	&__search{
		position: relative;
		&-input{
			border-radius: 0 $radius $radius 0;
		}
		&-btn{
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 60px;
			background: transparent;
			border: 0;
			cursor: pointer;
		}
		&-icon{
			width: 18px;
			height: 18px;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			fill: var(--main);
			&--text{
				fill: $text;
			}
		}
		&--width-1{
			width: 507px;
		}
		&--width-2{
			width: 450px;
		}
		&--width-3{
			width: 591px;
		}
	}
	&__search-input{
		input:focus {
			border-color: var(--main);
			& ~ .dropdown-search{
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&__dropdown{
		position: absolute;
		opacity: 0;
		visibility: hidden;
		transition: $tr-time;
		top: calc(100% + 5px);
		background-color: #fff;
		width: 100%;
		padding: 10px 15px;
		box-shadow: 0px 14px 17px rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		border: 1px solid #EAEAEA;
	}
}
.header-city{
	cursor: pointer;
	$this: &;
	&__value{
		font-size: 13px;
		line-height: 1.2;
	}
	&__arrow{
		width: 10px;
		height: 6px;
		margin-left: 9px;
		fill: $text;
	}
	&:hover{
		#{$this}__dropdown{
			transform: none;
			opacity: 1;
			pointer-events: all;
		}
	}
}
.header-catalog-btn{
	padding: 12px 16px;
	font-size: 15px;
	font-weight: normal;
	border-radius: $radius 0 0 $radius;
	transition: border-radius $tr-time;
	$this: &;
	&__arr{
		width: 12px;
		height: 8px;
		margin-left: 27px;
		transition: transform $tr-time;
	}
	&--filled{
		#{$this}__arr{
			fill: #fff;
		}
	}
	&--transparent{
		border: 1px solid $border;
		border-right: 0;
		&:hover{
			color: $text;
		};
	}
	&:hover{
		border-radius: 0;
		box-shadow: none;
		#{$this}__arr{
			transform: rotate(180deg);
		}
		& ~ .catalog-menu{
			opacity: 1;
			pointer-events: all;
		}
	}
}
.header-menu{
	&__link{
		position: relative;
		padding: 22px 0;
		font-size: 14px;
		line-height: 22px;
		&::after{
			content: "";
			display: block;
			height: 5px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: var(--main);
			opacity: 0;
			border-radius: 2px;
			transition: opacity $tr-time;
		}
		&:hover{
			color: $text;
			&::after{
				opacity: 1;
			}
		}
		&--offset{
			&:not(:last-child){
				margin-right: 30px;
			}
		}
	}
}
.header-action{
	font-size: 14px;
	line-height: 1;
	$action: & !global;

	&_compare{

	}

	&_wish{
		
	}

	&__icon{
		width: 24px;
		height: 24px;
		&-wrap{
			position: relative;
		}
		&--filled{
			fill: var(--main);
		}
	}
	&__text{
		margin-left: 13px;
	}
	&__count{
		width: calcFluid(20,22);
		height: calcFluid(20,22);
		position: absolute;
		left: 55%;
		bottom: 55%;
		font-size: calcFluid(12,14);
		line-height: 1;
		font-weight: 500;
		background-color: var(--additional);
		border-radius: 50%;
		transition: background-color $tr-time;
		&--main{
			color: #ffffff;
			background: var(--main);
		}
	}
	&--offset{
		margin-left: 30px;
	}
	&:hover{
		#{$action}__count{
			color: #fff;
			background-color: var(--main);
		}

		svg{
			fill: var(--main);
		}
	}
	&:active{

		svg{

			fill: var(--main-darker);

		}

	}
	&_disable{

		pointer-events: none;
		opacity: 0.8;

		svg{
			fill: #B6B6B6;
		}

		span{

			color: #B6B6B6;

		}

	}
}
.header-cart-btn{
	padding: 0 19px;
	&__icon{
		width: 24px;
		height: 24px;
		margin-right: 9px;
		fill: #fff;
	}
	&__text{
		margin-left: 12px;
		font-size: 14px;
		line-height: 1;
		font-weight: 700;
	}
	&--simple{
		padding: 0 0 0 16px;
	}
	&--border{
		position: relative;
		&::before{
			content: "";
			width: 1px;
			height: 23px;
			position: absolute;
			left: 0;
			bottom: 0;
			top: 0;
			margin: auto;
			background: #B6B6B6;
		}
	}
	&:hover{
		#{$action}__count{
			color: #fff;
			background-color: var(--main);
		}
	}
}
.header-burger{
	width: 20px;
	height: 14px;
	&__line{
		background-color: $text;
		&:first-child{
			transform: translateY(-5px);
		}
		&:last-child{
			transform: translateY(5px);
		}
	}
}
.header-mobile{
	position: sticky;
	top: 0;
	left: 0;
	z-index: 6;
	background: #ffffff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);

	&__content{

		padding: 14px 28px 14px 24px;

	}

	&__tel-svg{

		width: 16px;
		height: 16px;
		margin: 0;

	}

	&__wrapper-control{

		display: flex;
		align-items: center;

		& > * {

			&:first-child{

				margin-right: 15px;

			}

		}

	}

}

.header-subline{

	background-color: var(--main);
	height: 34px;

	&__wrapper{

		display: flex;
		justify-content: space-between;

		height: 100%;

	}

	&__link{

		display: flex;
		align-items: center;

	}

}

.subline-link{

	$this:&;

	display: flex;

	position: relative;

	color: #fff;

	&:hover{

		#{$this}{

			&__dropdown{

				opacity: 1;
				visibility: visible;

			}

			&__arrow{

				transform: rotate(180deg);

			}
	
		}

	}

	&__arrow{

		fill: #fff;

		margin-left: 7px;

	}

	&_dropdown{

		font-weight: bold;

	}

	&__dropdown{

		position: absolute;
		top: 100%;

		transition: all ease 400ms;

		opacity: 0;
		visibility: hidden;

		width: 100%;

		box-shadow: 0px 14px 17px rgba(0, 0, 0, 0.04);
		border-radius: 4px;

	}

	span{
		color: #fff;
	}

}

.subline-dropdown{

	$this:&;

	background-color: #fff;

	&__wrapper{

		max-height: 200px;
		padding: 15px 20px;

	}

	&__link{

		display: flex;
		width: 100%;

		font-weight: 400;

		&:not(:last-child){

			margin-bottom: 10px;

		}

	}

	&--width-content{
		width: fit-content;
		min-width: calc(100% + 50px);
		#{$this}__link{
			display: flex;
			white-space: nowrap;
		}

		.simplebar-wrapper{
			min-width: 100%;			
		}

		.simplebar-mask{
			width: 100% !important;
		}

		.simplebar-offset{
			width: fit-content;
		}

		.simplebar-content{
			width: fit-content;
		}

		.simplebar-content-wrapper{
			max-width: fit-content;
		}

	}

	.simplebar-vertical{

		margin: 15px 0;
		right: 10px;

	}

}

@media (min-width: 1200px) {
	.header-mobile{
		display: none;
	}
}
@media (max-width: 1199px) {
	.header-desktop{
		display: none;
	}
	.header{
		&__logo{
			height: 32px;
			&-icon{
				height: 100%;
			}
		}
	}
}