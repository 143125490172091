.header-1{
	&__middle{
		padding: 12px 0 13px;
	}
	&__workhours{
		margin-left: 56px;
		font-size: 14px;
		line-height: 1.2;
		color: #929292;
		&-text{
			margin-right: 10px;
		}
	}
	&__menu{
		margin-left: auto;
		$this: &;
		&-link{
			display: block;
			padding: 12px 0;
			color: #929292;
			font-size: 14px;
			line-height: 1.2;
			&:hover{
				color: #929292;
				text-decoration: underline;
			}
		}
		&-item{
			position: relative;
			&:hover{
				#{$this}-link{
					color: var(--main);
				}
				#{$this}-submenu{
					opacity: 1;
					pointer-events: all;
					transform: none;
				}
				&::before,&::after{
					pointer-events: all;
				}
			}
			&::before,&::after{
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				width: 18px;
				pointer-events: none;
			}
			&::before{
				right: 100%;
			}
			&::after{
				left: 100%;
			}
		}
		&-link,&-item{
			&:not(:first-child){
				margin-left: 32px;
			}
		}
		&-submenu{
			position: absolute;
			top: 100%;
			left: -18px;
			padding: 16px 30px 17px 23px;
			min-width: 215px;
			transform: translateX(-6px);
			z-index: 1;
			background-color: #fff;
			opacity: 0;
			box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.04);
			border-radius: 0 0 $radius $radius;
			border: 1px solid $border;
			pointer-events: none;
			transition: opacity $tr-time, transform $tr-time;
			&-link{
				display: block;
				font-size: 14px;
				line-height: 1.3;
				&:not(:last-child){
					margin-bottom: 14px;
				}
			}
		}
	}
	&__actions{

		max-width: 410px;
		padding: 0px 5px;
		margin: auto;
		
	}

	.header__search{
		margin-left: 20px;
	}
}