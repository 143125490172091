.advantages{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: #{$gap*2};
    row-gap: #{$gap*2};
}
.advantage{
    &__title{
        margin-bottom: 6px;
        font-weight: 500;
        font-size: calcFluid(15,18);
        line-height: calcFluid(20,26);
    }
    &__text{
        color: #929292;
        font-size: calcFluid(13,15);
        line-height: calcFluid(17,22);
    }
    &__icon{
        display: block;
        width: 55%;
        height: 55%;
        object-fit: scale-down;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        &-decor{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            &--part1{
                width: 100%;
                height: 100%;
                fill: var(--main);
            }
            &--part2{
                width: 76%;
                height: 76%;
                fill: var(--main-lighter);
            }
        }
    }
    &__icon-wrap{
        width: 100px;
        height: 100px;
        margin-bottom: calcFluid(8,15);
        position: relative;
    }
}

@media (max-width: 991px){
    .advantage{
        display: flex;
        &__icon-wrap{
            width: 50px;
            height: 50px;
            margin-right: 20px;
            flex: 0 0 auto;
        }
    }
    .advantages{
        row-gap: 25px;
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 575px){
    .advantage{
        display: flex;
        &__icon-wrap{
            width: 50px;
            height: 50px;
            margin-right: 20px;
            flex: 0 0 auto;
        }
    }
    .advantages{
        grid-template-columns: 1fr;
    }
}