.contact-item{
    &__value{
        font-size: 14px;
        line-height: 22px;
        color: var(--contact-item-color);
        &--bold{
            font-weight: 700;
        }
        &--bigger{
            font-size: 15px;
        }
        &--alt-color{
            color: var(--contact-item-alt-color);
        }
        &--underline{
            text-decoration: underline;
        }
    }
    &__icon{
        fill: var(--contact-item-icon-color);
        &-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            width: 18px;
            height: 22px;
            margin-right: 10px;
        }
    }
}