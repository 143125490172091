.header-6{

    .header{

        &__actions{

            flex-grow: 1;
            flex-shrink: 0;

            justify-content: space-between;

            max-width: 445px;
            padding: 0 15px;

            & > * {

                &:not(:last-child){

                    margin-right: 10px;

                }
                
            }

        }

        &-cart-btn{

            display: flex;
            flex-shrink: 0;

            width: fit-content;

        }

    }

    &__search{
        width: 500px;
    }


    &__subline{
        .subline-link{
            &:nth-of-type(n+5){
                display: none;
            }
        }
    }

}