@import 'base/mixins';

.partners-slider{
    position: relative;
    &__img{
        max-width: 175px;
        user-select: none;
        object-fit: scale-down;
        &--gray{
            transition: filter $tr-time;
            &:not(:hover){
                filter: saturate(0%);
            }
        }
    }
    &__controls{
        &--top-offset{
            margin-top: 24px;
        }
    }
    &__arrow{
        position: relative;
        z-index: 3;
    }
    &__show-all{
        margin-top: 0px;
        @include _large_ {
            display: none;
        }
    }
    &__slide{

        height: -webkit-fit-content;
        transition: all $tr-time;

        opacity: 1;
        visibility: visible;

        &_hidden{

            height: 0;
            transition: all $tr-time;

            opacity: 0;
            visibility: hidden;
        }

    }
}
@media(min-width: 1200px){
    .partners-slider-2{
        &__slide{
            padding: 0 73px;
        }
        &__arrow{
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 3;
            &.partners-slider__arrow--prev{
                left: 0;
            }
            &.partners-slider__arrow--next{
                right: 0;
            }
        }
        &::before,&::after{
            content: '';
            width: 73px;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 2;
            background: #ffffff;
        }
        &::before{
            left: 0;
        }
        &::after{
            right: 0;
        }
    }
    .partners-slider-3{
        &__arrow{
            margin-left: 19px;
        }
        &__controls{
            margin-bottom: 30px;
        }
    }
}
@media(max-width: 1199px){
    .partners-slider{
        &__controls{
            display: none;
        }
        &__slide{
            display: grid;
            grid-template-columns: repeat(4,auto);
            row-gap: 30px;

            margin-bottom: 30px;

            &_hidden{
                margin-bottom: 0px;
            }

        }
        &__img{
            max-width: 32vw;
        }
        .swiper-wrapper{
            flex-direction: column;
        }
    }
}
@media(max-width: 991px){
    .partners-slider{
        &__slide{
            grid-template-columns: repeat(3,auto);
        }
        &__img{
            max-width: 31.5vw;
        }
    }
}
@media(max-width: 575px){
    .partners-slider{
        &__slide{
            grid-template-columns: repeat(2,auto);
        }
        &__img{
            max-width: 39vw;
        }
    }
}