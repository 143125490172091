.footer-2{
    &__menus{
        padding: 0 #{getSingleColWidth() + $gap*2} 0 152px;
    }
    &__contacts-title{
        margin: calcFluid(30,45) 0 calcFluid(14,22);
        font-size: 15px;
        line-height: 20px;
    }
}

@media (max-width: 1199px){
    .footer-2{
        &__contacts-title{
            margin-top: 0;
        }
    }
}