.modal{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	&.is-open{
		display: block;
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(51, 51, 51, 0.25);
		overflow: auto;
	}
	&__close{
		position: absolute;
		right: 25px;
		top: 25px;
		cursor: pointer;
		svg{
			display: block;
			width: 14px;
			height: 14px;
			fill: $text;
			pointer-events: none;
		}
		&:hover{
			svg{
				fill: var(--main);
			}
		}
	}
	&__container{
		width: 100%;
		margin: 0 auto;
		position: relative;
		background-color: #ffffff;
		border-radius: 5px;
		overflow: hidden;
	}
	&__personal{
		margin-top: 10px;
		color: #c0c0c0;
	}
	&__title{
		font-weight: 700;
		font-size: 22px;
		line-height: 1.2;
	}
	&--default-animation{
		&[aria-hidden="false"]{
			.modal{
				&__overlay{
					animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
				}
				&__container{
					animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
				}
			}
		}
		&[aria-hidden="true"]{
			.modal{
				&__overlay{
					animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
				}
				&__container{
					animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
				}
			}
		}
	}
	&--alt-animation{
		&[aria-hidden="false"]{
			.modal{
				&__overlay{
					animation: mmfadeIn $tr-time cubic-bezier(0.0, 0.0, 0.2, 1);
				}
			}
			.modal-container{
				animation: slideFromRight $tr-time cubic-bezier(0.0, 0.0, 0.2, 1);
			}
		}
		&[aria-hidden="true"]{
			.modal{
				&__overlay{
					animation: mmfadeOut $tr-time cubic-bezier(0.0, 0.0, 0.2, 1);
				}
			}
			.modal-container{
				animation: slideToRight $tr-time cubic-bezier(0.0, 0.0, 0.2, 1);
			}
		}
	}
}
.modal-buy{

	z-index: 11;

	&__container{

		max-width: 340px;
		padding: 40px;

	}

	&__btn{

		width: 100%;
		height: 42px;

	}

	.form-2-item{

		margin-bottom: 20px;

	}

}
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

@keyframes slideFromRight{
	from { transform: translateX(2%); }
    to { transform: translateX(0); }
}
@keyframes slideToRight{
	from { transform: translateX(0); }
    to { transform: translateX(2%); }
}

@media (max-width: 575px){
	.modal{
		&__container{
			width: 90%;
		}
		&__close{
			top: 15px;
			right: 15px;
		}
	}
}