.btn{
	font-size: 15px;
	line-height: 1;
	font-weight: 700;
	border: 0;
	border-radius: $radius;
	cursor: pointer;
	transition: color $tr-time, border-color $tr-time, background-color $tr-time, box-shadow $tr-time;
	&--filled{

		color: #fff;
		background: var(--main);
		border: 1px solid var(--main);

		&:hover{
			color: #fff;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		}

		&:active, &:focus{
			background: var(--main-darker);
			border-color: var(--main-darker);
		}

		&_disable{

			color: #929292;
			background: #EAEAEA;
			border-color: transparent;

		}

	}

	&--filled-2{
		color: var(--main);
		border: 1px solid var(--main);
		&:hover{
			color: var(--main-darker);
			border-color: var(--main-darker);
		}
		&:active{
			color: #186F67;
			border-color: #186F67;
		}
		&:focus{
			background-color: var(--main-lighter);
		}
		&_disable{

			color: #B6B6B6;
			border-color: #B6B6B6;

		}
	}

	&--filled-3{

		color: #333333;
		border: 1px solid #333333;

		&:hover{
			color: var(--main);
			border-color: var(--main);
		}

		&:active{
			color: var(--main-darker);
			border-color: var(--main-darker);
		}

		&:focus{

			background-color: var(--main-lighter);

		}

		&_disable{

			color: #B6B6B6;
			border-color: #B6B6B6;

		}

	}

	&--empty{
		color: #929292;
		border-bottom: 1px dashed #B6B6B6;
	}

	&--white{
		color: var(--main);
		background: #ffffff;
	}

	&--auto-width{
		padding: 0 24px;
	}

	&--default-height{
		height: 42px;
	}

}