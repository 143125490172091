.footer-3{
    &__menus{
        padding: 0 56px 0;
        .footer__col{
            flex: auto;
        }
    }
    &__form-col{
        flex-basis: 370px;
    }
    &__form-title{
        &__icon{
            width: 27px;
            height: 21px;
            margin-right: 18px;
            fill: var(--main);
        }
    }
}