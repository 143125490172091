.index-categories{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    row-gap: #{$gap*2};
    column-gap: #{$gap*2};
    &__link{
        width: 192px;
        margin: calcFluid(20,40) auto 0;
    }
    &__show-all{
       
    }
    &__to-all{
        margin-top: 17px;
    }
    &.show-all{

        &::before{
            display: none;
        }

    }
}

@media (max-width: 1199px){
    .index-categories{
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
        column-gap: 10px;
        &__to-all{
            display: none;
        }
    }
}
@media (min-width: 576px){
    .index-categories{
        &__show-all{
            display: none;
        }
    }
}
@media (max-width: 575px){
    .index-categories{
        grid-template-columns: 1fr;
        &:not(.show-all){
            .category-block-2:nth-child(n+5){
                display: none;
            }
            .category-block-3:nth-child(n+5){
                display: none;
            }
        }
    }
}