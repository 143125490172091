.block-title{
    
    @include font-h1;

    &_margin{

        margin-bottom: calcFluid(30,40);

        &--page{
            margin-bottom: calcFluid(25,30);

            &-top{
                margin-top: 40px;
            }

        }

    }
}