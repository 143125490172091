.catalog-menu{
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	display: grid;
	grid-template-areas: "left submenu" ;
	grid-template-columns: 304px 1fr;
	grid-template-rows: repeat(999, auto);
	background-color: #fff;
	opacity: 0;
	box-shadow: 0px 14px 17px rgba(0, 0, 0, 0.04);
	border: 1px solid $border;
	border-radius: 0 $radius $radius $radius;
	pointer-events: none;

	transition: all ease 400ms;

	$this: &;
	&-wrap{
		position: relative;
		z-index: 1;
	}
	&__item{
		position: relative;
		border-right: 1px solid $border;
		&:hover{
			& + #{$this}__submenu{
				height: auto;
				padding: 50px 90px 20px 90px;
				opacity: 1;
			}
		}
	}
	&__link{
		display: block;
		padding: 15px 42px 15px 23px;
		position: relative;
		transition: color $tr-time, background-color $tr-time;
		$this: &;
		&-arr{
			width: 10px;
			height: 6px;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 26px;
			margin: auto;
			transform: rotate(-90deg);
		}
		&:hover{
			color: #fff;
			background: var(--main);
			#{$this}-arr{
				fill: #fff;
			}
		}
	}
	&__submenu{
		height: 0;
		padding: 0;
		margin-left: -1px;
		grid-area: submenu;
		grid-row-end: span submenu;
		opacity: 0;
		overflow: hidden;
		border-left: 1px solid $border;
		&-item{
			flex-basis: 26%;
			margin-bottom: 30px;
		}
		&-link{
			display: block;
			font-size: 15px;
			line-height: 20px;
			font-weight: 500;
		}
		&:hover{
			height: auto;
			padding: 50px 90px 20px 90px;
			opacity: 1;
		}
	}
	&__subcategories{
		padding-left: 20px;
		margin-top: 14px;
		$this: &;
		&-link{
			display: block;
			font-size: 14px;
			line-height: 18px;
			color: #929292;
			&:not(:last-child){
				margin-bottom: 10px;
			}
			&:nth-child(n+4){
				display: none;
			}
		}
		&-show-all{
			margin-top: 13px;
			font-size: 15px;
			line-height: 1;
			font-weight: 700;
			color: var(--main);
			cursor: pointer;
			transition: color $tr-time;
			&::before{
				content: "Показать все";
				border-bottom: 1px dashed var(--main);
				transition: border-color $tr-time;
			}
			&:hover{
				color: var(--main-darker);
				&::before{
					border-color: var(--main-darker);
				}
			}
		}
		&.show--all{
			#{$this}-show-all{
				&::before{
					content: "Скрыть";
				}
			}
			#{$this}-link{
				&:nth-child(n+4){
					display: block;
				}
			}
		}
	}
	&:hover{
		opacity: 1;
		pointer-events: all;
	}
}