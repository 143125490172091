.footer-1{
    &__menus{
        padding-left: 81px;
        padding-right: 98px;
    }
}
@media (max-width: 1199px){
    .footer-1{
        &__menus{
            padding: 0;
        }
    }
}