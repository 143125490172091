.footer{
    &--dark{
        color: #ffffff;
        background: #333333;
        --gray-main: #929292;
        --gray-alt: #B6B6B6;
        --contact-item-color: #929292;
        --contact-item-alt-color: #fff;
        --contact-item-icon-color: #929292;
        --menu-title-color: #fff;
        --info-color: #717171;
        --border-color: #484848;
    }
    &--light{
        color: #{$text};
        --gray-main: #B6B6B6;
        --gray-alt: #929292;
        --contact-item-color: #{$text};
        --contact-item-alt-color: var(--main);
        --contact-item-icon-color: #{$text};
        --menu-title-color: #{$text};
        --info-color: #929292;
        --border-color: #{$border};
    }
    &__top{
        padding: 45px 0;
    }
    &__bottom{
        padding: 21px 0;
        border-top: 1px solid var(--border-color);
    }
    &-logo{
        display: block;
        margin-bottom: 30px;
        &__img{
            display: block;
            max-width: 100%;
        }
    }
    &__col{
        flex: 0 0 192px;
    }
    &__contact-item{
        &:not(:last-child){
            margin-bottom: calcFluid(10,20);
        }
        &--small-offset{
            &:not(:last-child){
                margin-bottom: 8px;
            }
        }
    }
    &__icons{
        margin-top: 30px;
        &--small-offset{
            margin-top: 16px;
        }
    }
    &__soc-icon{
        &:not(:last-child){
            margin-right: 12px;
        }
    }
    &__menu{
        &-title{
            padding-bottom: 18px;
            color: var(--menu-title-color);
            font-size: 17px;
            line-height: 20px;
            font-weight: 700;
            &--border{
                margin-bottom: 13px;
                border-bottom: 1px solid #484848;
            }
        }
        &-link{
            display: block;
            font-size: 14px;
            line-height: 22px;
            color: var(--gray-alt);
            &:not(:last-child){
                margin-bottom: 8px;
            }
        }
    }
    &__form{
        &-col{
            flex: 0 0 300px;
        }
        &-title{
            margin-bottom: 6px;
            font-size: calcFluid(18,20);
            line-height: 26px;
            font-weight: 700;
        }
        &-text{
            margin-bottom: 20px;
            font-size: calcFluid(13,15);
            line-height: calcFluid(17,20);
        }
    }
    &__personal{
        color: var(--gray-main);
    }
    &__info{
        color: var(--info-color);
        font-size: 12px;
        line-height: 18px;
        &--underline{
            text-decoration: underline;
        }
        &:not(:last-child){
            margin-right: 30px;
        }
    }
    &__ps{
        margin-left: auto;
        color: var(--gray-main);
        $this: &;
        &-icon{
            width: 100px;
            height: 20px;
            margin-left: 7px;
            fill: var(--gray-main)
        }
        &:hover{
            #{$this}-icon{
                fill: var(--main);
            }
        }
    }
    &__cards{
        max-width: 191px;
        max-height: 13px;    
    }
}


@media (max-width: 1199px){
    .footer{
        &__top{
            flex-wrap: wrap;
        }
        &__menus{
            flex-basis: 100%;
            flex-wrap: wrap;
            margin: 0 -#{$containerPadding} 35px;
            padding: 0 $containerPadding 5px !important;
            border-bottom: 1px solid #484848;
        }
        &__bottom{
            margin: 0 -#{$containerPadding};
            padding-left: $containerPadding;
            padding-right: $containerPadding;
        }
        &__col{
            margin-bottom: 30px;
        }
        &__form-col{
            margin-bottom: 35px;
        }
        &__contacts-col{
            flex-basis: 100%;
            margin-bottom: 0 !important;
            order: 1;
        }
        &__icons{
            margin-top: 23px;
        }
    }
}
@media (max-width: 991px){
    .footer{
        &__menu-col{
            flex-basis: 25%;
        }
        &__bottom{
            align-items: flex-start;
            flex-direction: column;
        }
        &__info{
            &:not(:last-child){
                margin: 0 0 7px 0;
            }
        }
        &__cards{
            margin: 24px 0;
        }
        &__ps{
            margin-left: 0;
        }
    }
}
@media (max-width: 767px){
    .footer{
        &__col{
            flex-basis: 50%;
        }
    }
}
@media (min-width: 576px) and (max-width: 1199px){
    .footer{
        &__menu-col{
            padding-right: 15px;
        }
    }
}
@media (max-width: 575px){
    .footer{
        &__top, &__menus{
            flex-direction: column;
        }
        &__form-col,&__col,&__menus{
            flex-basis: auto !important;
        }
        &-logo{
            display: none;
        }
    }
}