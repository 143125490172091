.action-2{
    position: relative;
    border: 1px solid $border;
    border-radius: $radius;
    overflow: hidden;
    transition: border-color $tr-time, box-shadow $tr-time;
    &:hover{
        border-color: transparent;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    }
}
.actions-grid{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    column-gap: #{$gap*2};
    row-gap: #{$gap*2};
    align-items: start;
}

@media (min-width: 1200px){
    .action-2{
        &--normal{
            min-height: 350px;
            grid-column-start: span 3;
            grid-row-start: span 2;
        }
        &--wide{
            min-height: 159px;
            grid-column-start: span 3;
        }
        &--large{
            min-height: 458px;
            grid-column-start: span 4;
            grid-row-start: span 2;
        }
        &--small{
            min-height: 213;
            grid-column-start: span 2;
        }
        &--last{
            order: 1
        }
    }
}
@media (max-width: 1199px){
    .actions-grid{
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        row-gap: 15px;
    }
    .action-2{
        &__bg{
            position: static;
            .bg__img{
                height: auto;
                position: static;
            }
        }
    }
}
@media (max-width: 575px){
    .action-2{
        grid-column-start: span 2;
    }
    .actions-grid{
        grid-template-columns: 1fr 1fr;
    }
}