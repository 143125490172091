.index-block{

    margin-bottom: calcFluid(35,70);

    .block-title{

        font-size: calcFluid(20,36);

    }

    h1{

        font-size: calcFluid(20,36) !important;

    }

}

.hidden{

    &--mobile{

        @include _small {
            
            display: none;
            
        }

    }

}