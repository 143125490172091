.modal-cities{
	&__container{
		max-width: 530px;
		padding: 40px;
	}
	&__title{
		margin-bottom: 30px;
	}
	&__list{
		max-height: calcFluid(215,255);
		&-item{
			display: block;
			padding-right: 10px;
			font-size: calcFluid(14,16);
			line-height: 1.5;
			&:not(:first-child){
				margin-top: calcFluid(11,15);
			}
		}
	}
	&__search{
		margin-bottom: calcFluid(15,20);
		&-btn{
			padding: 0 calcFluid(18,24);
			border-radius: 0 $radius $radius 0;
		}
		&-input{
			border-radius: $radius 0 0 $radius;
		}
	}
}

@media (max-width: 575px){
	.modal-cities{
		&__container{
			padding: 28px 20px;
		}
		&__title{
			margin-bottom: 18px;
			font-size: 18px;
		}
	}
}