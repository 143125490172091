.header-4{
    &__menu{

        padding: 0 60px;

        justify-content: flex-start;

        & > * {

            &:not(:last-child){
                margin-right: 15px;
            }

        }

    }
    &__search{
        &-btn{
            width: 122px;
            background: var(--main);
        }
        &-icon{
            fill: #fff;
        }
    }
    &__logo{
        margin-right: 70px;
    }
}