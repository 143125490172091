.mobile-menu{
    width: 100%;
    position: fixed;
    top: 60px;
    bottom: 0;
    z-index: 5;
    pointer-events: none;
    $this: &;
    &__wrap{
        width: 100%;
        min-height: 100%;
        position: relative;
        z-index: 5;
        left: 100%;
        background: #ffffff;
        transition: transform $tr-time;
    }
    &__link{
        display: block;
        font-size: calcFluid(14,16);
        line-height: 1.15;
        color: $text;
        &:not(:last-child){
            margin-bottom: calcFluid(20,25);
        }
    }
    &__catalog{
        list-style: none;
        .menu-item{
            >a{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 20px;
                font-size: 15px;
                line-height: 20px;
                border-bottom: 1px solid $border;
            }
        }
        
    }
    &__sub-menu{
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        background-color: #ffffff;
        overflow: auto;
        transition: transform $tr-time;
        &.opened{
            transform: translateX(-100%);
        }
        .menu-item{
            >a{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 20px;
                font-size: 15px;
                line-height: 20px;
                border-bottom: 1px solid $border;
            }
        }
    }
    &__back{
        >a{
            padding: 12px 20px !important;
            color: #ffffff;
            background: var(--main);
            justify-content: flex-start !important;
        }
        svg{
            margin-right: 16px;
            fill: #fff
        }
    }
    &__catalog-link{
        display: block;
        padding: 12px 20px;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff !important;
        background: var(--main);
    }
    &__search{
        position: relative;
        &-input{
            height: 54px;
            padding: 0 20px;
            background: #F8F8F8;
        }
        .form-label{
            left: 20px;
        }
    }
    &__city{
        padding: 10px 20px;
        &-value{
            font-size: 14px;
            line-height: 22px;
        }
    }
    &__actions{
        border-bottom: 1px solid $border;
        .header-action{
            height: 52px;
            &:not(:last-child){
                border-right: 1px solid $border;
            }
        }
    }
    &__menu{
        padding: 15px 0;
        background: #F8F8F8;
        list-style: none;
        >.menu-item{
            >a{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 20px;
                color: #929292;
                font-size: 14px;
                line-height: 22px;
                .menu-item__arrow{
                    fill: #929292;
                }
            }
        }
    }
    &__contact{
        padding: 16px 34px;
        margin: 0 !important;
        &:not(:last-child){
            border-bottom: 1px solid $border;
        }
    }
    &.opened{
        pointer-events: all;
        overflow-x: hidden;
        #{$this}__wrap{
            transform: translateX(-100%);
        }
    }
    .opened--sub-menu{
        height: 100%;
        overflow: hidden;
    }
}
.menu-item__arrow{
    width: 10px;
    height: 6px;
    flex: 0 0 auto;
    margin-left: 10px;
    transform: rotate(-90deg);
    &--to-left{
        margin-left: 0;
        transform: rotate(90deg);
    }
}

@media (min-width: 1200px){
    .mobile-menu{
        display: none;
    }
}