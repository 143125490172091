@import 'base/mixins';

.product-card{

    $this: &;

    display: flex;

    position: relative;

    height: -webkit-fit-content;

    z-index: 1;
    background: #ffffff;

    transition: box-shadow $tr-time, border-color $tr-time;

    
    &_column{

        width: 100%;
        height: 100%;

        flex-direction: column;

        border: 1px solid $border;
        border-radius: $radius;

        @include _Extra_large_ {

            #{$this}{

                &__sidebar{

                    display: block;
    
                }
            
            }

            &:hover{

                color: $text;

                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;

                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
                
                z-index: 2;

                #{$this}{

                    &__quick-view{
                        opacity: 1;
                    }

                    &__bottom{
                        transform: translateY(100%);
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
                    }

                    &__link{
                        border: 1px solid var(--main);
                    }

                    &__props{

                        visibility: visible;
                        opacity: 1;

                    }

                }
    
            }
            
        }

        #{$this}{

            &__bottom{

                position: absolute;

                width: calc(100% + 2px);
                border: 1px solid #eaeaea;
                border-top: none;

                bottom: 0;
                left: -1;

                z-index: -1;

                @include _Extra_large {

                    margin-top: auto;

                    position: relative;
                    border-bottom: none;
                   
                }

            }

            &__wrapper{

                @include _small {

                    flex-direction: row;

                    padding-left: 10px;
                    padding-right: 20px;
                    padding-top: 17px;
                    padding-bottom: 18px;
                    
                }

            }

            &__img{

                width: 100%;
                margin: auto;

                &-wrap-2{

                    @include _small_ {

                        padding: 25px 0;
                        
                    }

                    @include _small {

                        // width: 45%;

                        flex-shrink: 0;

                        width: 29vw;
                        height: 19vw;
                        
                    }

                }
                
                @include _small_ {

                    height: 152px;
                    
                }

                @include _Extra_large {
                    
                    @include _small_ {

                        max-height: 160px;

                    }

                }

                &_cup{

                    background-color: #F1F7FA;

                }

            }

            &__info{

                display: flex;
                flex-direction: column;

                border-radius: 0 0 $radius $radius;
                flex-grow: 1;

                
                
                @include _small_ {

                    padding: 30px 27px 18px;

                    .product-card__price-block{

                        margin-bottom: 18px;

                    }
                    
                }

                @include _small {

                    margin-left: 10px;

                    .product-card__price-block{
                        order: 1;
                    }

                    .product-card__name{

                        margin-bottom: 10px;

                    }

                }

            }

            &__name{

                font-size: 16px;
                line-height: 24px;

                font-weight: 500;

                @include _max {

                    font-size: calcFluid(13,16);
                    line-height: calcFluid(21,24);
                    
                }
            }
            
            &__link{

                border-radius: 0 $radius 0 $radius;
                border: 1px solid #eaeaea;
                
                &-wrap{
                    
                    flex: 0 0 63.6%;

                    transform: translate(-1px, 1px);

                }

                @include _Extra_large {
                    border: 1px solid var(--main);
                }

            }

            &__quick-view{

                @include _Extra_large {
                    
                    display: none;

                }

            }

            &__description{

                @include _small {
                    display: none;
                }

            }

            &__delete{

                display: flex;

                position: absolute;
                
                width: 22px;
                height: 22px;
                
                top: 10px;
                right: 10px;

                z-index: 1;

                &:hover{

                    svg{

                        fill: var(--main);

                    }

                }

                a{

                    margin: auto;

                }

                svg{

                    fill: #B6B6B6;

                }

            }

            &__props{

                display: flex;
                flex-direction: column;

                position: absolute;

                width: 100%;
                height: 100%;

                top: 0;
                left: 0;

                z-index: 1;

                &::before{

                    content: '';

                    position: absolute;

                    height: 100%;
                    width: 100%;

                    background-color: var(--main);

                    opacity: 0.93;

                    z-index: -1;

                }

                &-wrapper{

                    width: 100%;
                    height: 100%;

                    padding: 25px;

                }

                &-item{

                    display: flex;
                    flex-wrap: wrap;

                    align-items: center;
                    justify-content: space-between;

                    &:not(:last-child){

                        margin-bottom: 2px;

                    }

                    span{

                        display: block;

                    }

                }

                &-name{

                    color: #DAF4F3;

                    font-size: 12px;
                    line-height: 20px;

                    padding-right: 5px;

                }

                &-value{

                    color: #fff;

                    font-size: 12px;
                    font-weight: bold;
                    line-height: 20px;

                }

            }
        
        }

    }

    &_row{

        flex-direction: row;

        border-top: 1px solid $border;

        height: 100px;

        &-bet{

            border-left: 1px solid #eaeaea;
            border-right: 1px solid #eaeaea;
    
            &:first-child{
    
                border-top-color: #eaeaea;
    
            }

            &.unavailable{

                background-color: rgba(211,211,211,.5);

                #{$this}__img-wrap-2{
                    opacity: .5;
                }

                #{$this}__info{
                    background-color: transparent;
                    opacity: .5;
                }

                #{$this}__bottom{
                    background-color: transparent;
                }

                #{$this}__link-wrap{

                    pointer-events: none;
                    opacity: 0;
                    visibility: hidden;

                }

            }
    
            &:hover{
    
                border-color: transparent;
    
                & + * {
    
                    border-top-color: transparent;
    
                }
    
            }
    
            #{$this}{
    
                &__info{
    
                    margin-left: 20px;
                    max-width: inherit;
                    flex-grow: 1;
                    justify-content: flex-start;
    
                }
    
                &__price-block{
    
                    width: fit-content;
    
                }
    
                &__name{
    
                    display: flex;
                    min-width: 480px;
    
                }
    
                &__bottom{
    
                    width: 300px;
                    flex-shrink: 0;
    
                }
    
                &__link{
    
                    width: 156px;
    
                }
    
            }
    
        }
    
        #{$this}{

            &__wrapper{
                display: contents;
            }

            &__img{

                object-fit: contain;

                width: 120px;

                padding: 10px;
                margin-right: 20px;

                &_cup{

                    #{$this}__img{

                        background-color: #F1F7FA;

                    }

                }
                
            }

            &__quick-view{

                display: none;

            }

            &__info{

                display: flex;

                width: 100%;
                max-width: 475px;

                align-items: center;
                justify-content: space-between;

            }

            &__description{

                display: none;

            }

            &__price{

                font-size: 18px;

            }

            &__price-block{

                flex-shrink: 0;

                order: 2;
                
                width: 160px;
                max-width: 200px;

            }

            &__name{

                max-width: 260px;

                font-size: 15px;
                font-weight: normal;

                line-height: 20px;

            }

            &__link{
                width: 112px;

            }

            &__bottom{

                width: 216px;

                margin: auto;
                margin-right: 15px;

            }

            &__sidebar{

                display: none;

            }

        }

        &:first-child{

            border-top-color: transparent;

        }

        &:hover{

            box-shadow: 0 10px 20px rgba(0,0,0,.05);
            // border-top-color: transparent;
            z-index: 2;

            & + div{

                border-top-color: transparent;

            }

        }

    }

    &_variation{

        #{$this}{

            &__quick-view{

                display: none;

            }
        
        }

        &:hover{

            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;

            #{$this}{

                &__sidebar{
                    
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(calc(-100% - 1px));

                }

                &__link{

                    border-bottom-left-radius: 0px;

                }

            }

        }            

    }

    &_cart{

        display: table-row;

        &.favorite{

            .svg-favorite{

                use{
    
                    &:first-child{
    
                        display: none;
    
                    }
    
                    &:last-child{
    
                        display: block;
    
                        fill: var(--main);
        
                    }
    
                }
        
            }

        }

        &.removed{

            .cart-list__column{

                &:not(:last-child):not(:first-child){

                    opacity: 0.6;

                }

            }

            .product-card__action_close{

                display: none;

            }

            .product-card__action_repeat{

                display: block;

                svg{

                    fill: var(--main);

                }

            }

        }

        &.unavailable{

            .cart-list__column{

                &:not(:first-child){

                    background-color: rgba(211, 211, 211, 0.5);

                }

                &:not(:last-child):not(:first-child){

                    & > * {

                        opacity: 0.5;
    
                    }

                }

            }

            .product-card__info {

                background: transparent; 

            }

            .product-card__amount{

                display: none;

            }

            .article-counter{

                display: none;

            }

            .product-card__price{

                display: none;
                
                &_visible{
                    display: block;
                }

            }

            .product-card__bottom{

                background-color: transparent;
                
            }

            .filter-item{

                background-color: #fff;
                opacity: 1 !important;

            }

        }

        #{$this}{

            &__bagdes{

                left: 4px;
                top: 4px;

                font-size: 11px;

            }

            &__action{

                flex-grow: 0;

                &-svg{

                    width: 24px;
                    height: 24px;

                    fill: #B6B6B6;

                }

                &_favorite{

                }

                &_close{

                    svg{

                        width: 16px;
                        height: 18px;

                    }

                }

            }

            &__bottom{

                justify-content: space-between;

            }

            &__amount{

                white-space: nowrap;

                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                line-height: 22px;

            }

            &__old-price{

                white-space: nowrap;

            }

            &__img{

                object-fit: contain;

            }

        }

    }

    &_action--more{

        #{$this}__link{

            &::before{

                content: 'Подробнее';

            }

        }

    }

    &_action--cart{

        #{$this}__link{

            &::before{

                content: 'В корзину';

            }

        }

    }

    &_delete{

        #{$this}__delete{
            display: block;
        }

    }

    
    &__sidebar{

        display: none;

        position: absolute;

        background-color: #fff;

        border: 1px solid #eaeaea;
        border-right: 0;
        box-shadow: -3px 12px 20px rgba(0, 0, 0, 0.05);
        border-radius: 4px 0px 0px 4px;
        
        padding-top: 20px;
        padding-bottom: 20px;

        padding-left: 15px;
        
        height: calc(100% + 44px);

        width: 100px;

        opacity: 0;
        visibility: hidden;
        transition: all ease 400ms, opacity ease 200ms;

        top: -1px;
        left: 0;

        &-wrap{

            margin-right: 12px;

        }

    }

    &__variation{

        display: block;
        
        object-fit: cover;
        object-position: center;

        margin: auto;
        
        width: 48px;
        height: 48px;
        
        max-width: 100%;
        max-height: 100%;

        &-wrap{

            display: flex;

            border: 1px solid #EAEAEA;
            border-radius: 4px;

            width: 60px;
            height: 62px;

            &:not(:last-child){

                margin-bottom: 15px;

            }

        }

    }

    &__wrapper{

        display: flex;
        flex-direction: column;

        height: 100%;

    }

    &__img{

        $image:&;
    
        // display: block;
        // object-fit: scale-down;

        display: block;
        
        object-fit: cover;
        object-position: center;
        
        width: 100%;
        height: 100%;
        
        max-width: 230px;
        max-height: 152px;

        @include _Extra_large {

            // max-width: 180px;
            // max-height: 160px;
            // margin: 16px 0 0 9px;
            // margin: auto;
            // flex: 0 0 auto;
            
        }

        @include _medium_ {

            @include _large {
                
                // max-width: 200px;

            }
            
        }

        &-wrap{

            display: flex;
            height: inherit;

            height: 70px;

            #{$image}{

                width: 100%;
            
            }

        }

        &-wrap-2{

            display: flex;
            position: relative;

        }

        &_cup{

            #{$image}{
                
                display: flex;

                .svg-no-image{

                    fill: grey;
                    width: 50%;
                    height: 50%;
                    margin: auto;

                }
            
            }

            svg{

                margin: auto;

            }

        }

    }

    &__price{
        font-size: 22px;
        line-height: 1;
        font-weight: 700;

        @include _max {

            font-size: calcFluid(20,22);
            
        }

        &-from{
            font-size: 17px;
        }
        &--no-price{

            font-size: 20px;

            @include _max {

                font-size: calcFluid(16,20);
                
            }
        }
        &--cart,
        &--currency{

            white-space: nowrap;
            font-weight: bold;

            font-size: 16px;
            line-height: 22px;

        }
        &_visible{
            display: none;
        }
        &_wrap{

        }
    }

    &__price-block{

        @include _small {
            
            margin-top: auto;
            
        }
        
    }

    &__info{
        background: #ffffff;
    }

    &__quick-view{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 202px;
        opacity: 0;
        border-radius: $radius $radius 0 0;
        overflow: hidden;
        transition: opacity $tr-time;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: var(--main);
            opacity: 0.3;
        }
        &__btn{
            position: relative;
            padding: 0 24px;
        }
    }

    &__old-price{

        font-size: 15px;
        line-height: 20px;

        color: #cccccc;

        text-decoration: line-through;

    }

    &__discount{
        margin-right: 13px;
        font-size: 12px;
    }

    &__price-row{
        margin-bottom: 3px;
    }

    &__bottom{
        
        background: #ffffff;
        border-radius: 0 0 $radius $radius;
        transition: transform $tr-time;

    }

    &__link{

        &:before{

            content: 'Подробнее';

        }
        
    }

    &__action{

        display: flex;

        align-items: center;
        justify-content: center;
        flex-grow: 1;
        cursor: pointer;
        
        &_repeat{
            display: none;
        }

        

        &:hover{
            .header-action__icon{
                fill: var(--main);
            }
        }
    }

    &__description{
        margin-top: 6px;
        font-size: 15px;
        line-height: 20px;
        color: #929292;
    }

    &__bagdes{
        left: 13px;
        top: 13px;
        font-size: 12px;
    }

    &__delete{

        display: none;

    }

    &__warning{

        right: 0px;

        transform: translate(50%,-50%);

    }

    &__props{

        display: none;

        visibility: hidden;
        opacity: 0;

        transition: visibility ease 400ms, opacity ease 400ms;
        
    }
   
}

.product-bagdes{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
}

.product-bagde{
    display: inline-block;
    padding: 4px 5px;
    color: #ffffff;
    line-height: 1;
    border-radius: 2px;
    &--discount{
        background: #ED6262;
    }
    &--new{
        background: var(--main);
    }
    &--hit{
        color: $text;
        background: var(--additional);
    }
    &--bottom-offset{
        &:not(:last-child){
            margin-bottom: 4px;
        }
    }
}
