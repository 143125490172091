.header-5{
    &__catalog-btn{
        margin-right: 15px;
        border-radius: $radius;
    }
    &__search{
        &-input{
            border-radius: $radius;
        }
    }
    &__burger{
        width: 18px;
        height: 12px;
        margin-right: 14px;
        &__line{
            background: #ffffff;
            &:first-child{
                transform: translateY(-4px);
            }
            &:last-child{
                transform: translateY(4px);
            }
        }
    }
}