.custom-scrollbar{
	.simplebar{
		&-track{
			background-color: #EAEAEA;
			&.simplebar-vertical{
				width: 2px;
				.simplebar-scrollbar::before{
					top: 0;
					bottom: 0;
				}
			}
		}
		&-scrollbar{
			&::before{
				left: 0;
				right: 0;
				background-color: var(--main);
				opacity: 1 !important;
				border-radius: 0;
			}
		}
	}
}