@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url(/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);
body {
  min-height: 100vh;
  margin: 0;
  color: #333;
  font-family: 'Roboto', sans-serif; }

main {
  position: relative; }

p, ol, ul, h1, h2, h3, button, form {
  margin: 0;
  padding: 0; }

button {
  font-family: inherit; }
  button:focus {
    outline: none; }

h1, h2 {
  font-weight: normal; }

button, input, textarea {
  font-family: inherit; }
  button:focus, input:focus, textarea:focus {
    outline: none; }

input {
  min-width: 0;
  font-size: inherit;
  appearance: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield; }

a {
  color: #333;
  text-decoration: none;
  transition: color 0.35s; }
  a:hover {
    color: var(--main); }

svg {
  vertical-align: middle;
  transition: fill 0.35s; }

picture {
  display: block; }

img {
  display: block; }

source {
  display: none; }

*, ::before, ::after {
  box-sizing: border-box; }

:focus {
  outline: none; }

.d-flex {
  display: flex; }

.d-iflex {
  display: inline-flex; }

.ai-center {
  align-items: center; }

.ai-flex-end {
  align-items: flex-end; }

.ai-flex-start {
  align-items: flex-start; }

.jc-center {
  justify-content: center; }

.jc-space-between {
  justify-content: space-between; }

.jc-flex-end {
  justify-content: flex-end; }

.fw-wrap {
  flex-wrap: wrap; }

.fl-auto {
  flex: 0 0 auto; }

.fl-grow {
  flex-grow: 1; }

.fl-full {
  flex: 1 1 100%; }

.fd-column {
  flex-direction: column; }

.as-stretch {
  align-self: stretch; }

.hidden {
  display: none !important; }

.overflow-auto {
  overflow: auto; }

.overflow-hidden {
  overflow: hidden; }

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  user-select: none; }
  .bg__img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lazy-load.loading {
  opacity: 0; }

.adaptive-img {
  position: relative; }
  .adaptive-img__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.page {
  position: relative;
  padding-bottom: 70px;
  overflow: hidden; }
  .page-link {
    display: flex;
    z-index: 1;
    flex-direction: column; }

.load * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important; }

@media (max-width: 1199px) {
  .container {
    max-width: 100%; } }

.container {
  width: 100%;
  max-width: 1342px;
  margin: 0 auto;
  padding: 0 15px; }

.row {
  margin: 0 -16px; }

.col-gap {
  margin: 0 16px; }

.grid-gap {
  column-gap: 32px;
  row-gap: 32px; }

.show-all {
  margin-top: 15px;
  color: var(--main);
  font-size: 15px;
  line-height: 1;
  cursor: pointer; }
  .show-all_hidden {
    display: none; }
  .show-all svg {
    width: 10px;
    height: 6px;
    margin-left: 13px;
    fill: var(--main);
    transition: transform 0.35s; }
  .show-all::before {
    content: "Показать еще"; }
  .show-all.active::before {
    content: 'Скрыть'; }
  .show-all.active svg {
    transform: rotate(180deg); }

.show-more {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main);
  border-radius: 4px;
  background: transparent;
  width: 100%;
  height: 42px; }
  .show-more::before {
    content: 'Показать еще';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #fff; }

.select-all {
  display: flex;
  align-items: center; }
  .select-all .filter-item__select {
    margin-right: 11px; }

.pagination {
  display: flex;
  width: fit-content;
  height: 42px;
  margin: 0 auto; }
  .pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    width: 44px;
    cursor: pointer;
    transition: all ease 400ms; }
    .pagination-item:hover {
      background-color: #EBF7F6;
      border-radius: 5px; }
      .pagination-item:hover .pagination-link {
        color: var(--main); }
    .pagination-item_arrow {
      width: 30px;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 4px; }
      .pagination-item_arrow-left {
        margin-right: 20px; }
      .pagination-item_arrow-right {
        margin-left: 20px; }
    .pagination-item_current {
      position: relative;
      pointer-events: none; }
      .pagination-item_current .pagination-link {
        color: var(--main); }
      .pagination-item_current:after {
        content: '';
        position: absolute;
        width: 40px;
        height: 2px;
        background-color: var(--main);
        top: 100%; }
  .pagination-link {
    pointer-events: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #B6B6B6; }

.block-list {
  padding: 20px;
  background-color: #EBF7F6; }
  .block-list__item {
    display: block;
    height: auto;
    transition: all ease 400ms;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    width: 84%; }
    .block-list__item:not(:last-child) {
      margin-bottom: 18px; }
    .block-list__item_hidden {
      display: none; }
    @media (max-width: 575px) {
      .block-list__item {
        width: 100%;
        font-size: 15px; }
        .block-list__item:not(:last-child) {
          margin-bottom: 14px; } }
  .block-list__wrapper {
    position: relative; }
  .block-list__show-all::before {
    content: 'Показать все'; }
  .block-list__show-all_active::before {
    content: 'Свернуть'; }
  .block-list__show-all_active svg {
    transform: rotate(180deg); }

.block-receive {
  display: block;
  padding: 25px 20px;
  border: 1px solid #EAEAEA;
  border-radius: 3px;
  height: fit-content;
  background: #FBFBFB; }
  .block-receive__item:not(:last-child) {
    padding-bottom: 25px;
    margin-bottom: 15px;
    border-bottom: 1px solid #EAEAEA; }
  .block-receive-item {
    padding: 0 12px; }
    .block-receive-item__header {
      display: flex;
      align-items: center;
      margin-bottom: 10px; }
    .block-receive-item__title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: var(--main); }
    .block-receive-item__svg {
      width: 36px;
      height: 36px;
      margin-right: 12px;
      fill: var(--main); }
    .block-receive-item__content {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: #929292; }

.block-subscribe {
  background-color: #333333;
  color: #fff;
  padding: 35px 0; }
  .block-subscribe__title {
    font-size: calc(0.00125 * 100vw + 17.6px);
    margin-bottom: 8px; }
  .block-subscribe__text {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px; }
  .block-subscribe_mod-1 .block-subscribe__tilte {
    margin-bottom: 8px; }
  .block-subscribe_mod-1 .block-subscribe__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1199px) {
      .block-subscribe_mod-1 .block-subscribe__wrapper {
        flex-direction: column;
        align-items: flex-start; }
        .block-subscribe_mod-1 .block-subscribe__wrapper > * {
          width: 100%; }
          .block-subscribe_mod-1 .block-subscribe__wrapper > *:not(.block-subscribe__wrapper-form) {
            margin-bottom: 25px; } }
    @media (max-width: 1199px) and (min-width: 576px) {
      .block-subscribe_mod-1 .block-subscribe__wrapper > * {
        max-width: 420px; } }
  @media (min-width: 1200px) {
    .block-subscribe_mod-1 .block-subscribe__wrapper-text {
      width: 416px; } }
  .block-subscribe_mod-1 .block-subscribe__wrapper-form {
    flex-grow: 1; }
    @media (min-width: 1200px) {
      .block-subscribe_mod-1 .block-subscribe__wrapper-form {
        max-width: 528px;
        padding: 0 15px; } }
  .block-subscribe_mod-1 .block-subscribe__wrapper-info {
    width: 270px; }
    @media (min-width: 1200px) {
      .block-subscribe_mod-1 .block-subscribe__wrapper-info {
        margin-top: 10px;
        margin-bottom: auto; } }
    @media (max-width: 1199px) {
      .block-subscribe_mod-1 .block-subscribe__wrapper-info {
        order: -1; } }
  .block-subscribe_mod-2 {
    background-color: #2C2C2C; }
    .block-subscribe_mod-2 .block-subscribe__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 1199px) {
        .block-subscribe_mod-2 .block-subscribe__wrapper {
          flex-direction: column;
          align-items: flex-start; }
          .block-subscribe_mod-2 .block-subscribe__wrapper > * {
            width: 100%;
            max-width: 420px; }
            .block-subscribe_mod-2 .block-subscribe__wrapper > *:not(:last-child) {
              margin-bottom: 25px; } }
    @media (min-width: 1200px) {
      .block-subscribe_mod-2 .block-subscribe__wrapper-text {
        width: 254px; } }
    @media (min-width: 1200px) {
      .block-subscribe_mod-2 .block-subscribe__wrapper-info {
        width: 416px; } }
    .block-subscribe_mod-2 .block-subscribe__wrapper-form {
      flex-grow: 1; }
      @media (min-width: 1200px) {
        .block-subscribe_mod-2 .block-subscribe__wrapper-form {
          max-width: 530px; } }
  .block-subscribe .personal {
    margin-top: 8px;
    color: #929292; }

.block-subscribe-2 {
  position: relative; }
  .block-subscribe-2__title {
    font-size: calc(0.00125 * 100vw + 17.6px);
    margin-bottom: 8px; }
  .block-subscribe-2__text {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px; }
  .block-subscribe-2__wrapper {
    display: flex; }
    @media (max-width: 1199px) {
      .block-subscribe-2__wrapper > * {
        width: 100%; } }
  @media (max-width: 1199px) and (min-width: 576px) {
    .block-subscribe-2__wrapper > * {
      max-width: 420px; } }
  @media (min-width: 1200px) {
    .block-subscribe-2__wrapper-content {
      width: 530px;
      margin-right: 70px; } }
  .block-subscribe-2__separate {
    margin-bottom: 35px; }
  .block-subscribe-2__image {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%; }
    .block-subscribe-2__image-wrap {
      position: absolute;
      display: flex;
      width: 53vw;
      height: 230px;
      right: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      overflow: hidden; }
      @media (max-width: 1199px) {
        .block-subscribe-2__image-wrap {
          display: none; } }
  .block-subscribe-2__separate {
    width: 270px; }
  .block-subscribe-2 .form-wrap__content {
    max-width: 100%;
    margin-bottom: 25px; }
    @media (min-width: 1200px) {
      .block-subscribe-2 .form-wrap__content {
        width: 390px; } }
  .block-subscribe-2 .personal {
    color: #929292; }

.block-info-separate {
  display: flex;
  justify-content: space-between; }
  .block-info-separate__text {
    font-style: normal;
    font-weight: normal;
    color: #929292;
    font-size: 15px;
    line-height: 20px; }
  .block-info-separate__line {
    width: 1px;
    background-color: #929292; }
  .block-info-separate > *:first-child {
    padding-right: 15px; }
  .block-info-separate > *:last-child {
    padding-left: 15px; }
  .block-info-separate .contact-item {
    width: 50%;
    word-break: break-word; }

.search {
  display: flex;
  height: 47px;
  position: relative;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  overflow: hidden; }
  .search__input {
    display: block;
    width: 100%;
    border: 0;
    padding: 0 12px; }
    .search__input::-webkit-input-placeholder {
      font-size: 15px;
      line-height: 20px;
      color: #B6B6B6; }
  .search__label {
    position: absolute;
    font-size: 15px;
    line-height: 20px;
    color: #B6B6B6;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition: 0.35s; }
  .search__svg {
    position: absolute;
    fill: #B6B6B6;
    width: 19px;
    height: 100%;
    right: 14px;
    top: 50%;
    transform: translateY(-50%); }

.toggle-view {
  display: flex; }
  .toggle-view-item {
    cursor: pointer; }
    .toggle-view-item:hover svg {
      fill: var(--main); }
    .toggle-view-item:first-child {
      margin-right: 12px; }
    .toggle-view-item_active .toggle-view-item__svg {
      fill: #333333; }
    .toggle-view-item__svg {
      fill: #B6B6B6;
      width: 24px;
      height: 24px; }
      @media (min-width: 992px) {
        .toggle-view-item__svg {
          width: calc(0.00431 * 100vw + 15.73305px);
          height: calc(0.00431 * 100vw + 15.73305px); } }
      @media (max-width: 991px) {
        .toggle-view-item__svg {
          display: none; } }

.svg-pagination {
  width: 9px;
  height: 16px;
  fill: #B6B6B6;
  border-radius: 0.8px; }

.svg-favorite:hover {
  fill: var(--main) !important; }

.svg-favorite use {
  display: block; }
  .svg-favorite use:last-child {
    display: none; }

.svg-favorite_active use:first-child {
  display: none; }

.svg-favorite_active use:last-child {
  display: block;
  fill: var(--main); }

.svg-wish {
  fill: #B6B6B6; }
  .svg-wish__use-full {
    fill: var(--main); }

.svg-compare {
  fill: #B6B6B6; }

.svg-close:hover {
  fill: var(--main) !important; }

.social {
  display: flex;
  flex-wrap: wrap;
  margin: -6px 0; }
  .social-item {
    display: flex;
    margin: 6px;
    width: 31px;
    height: 31px; }
    .social-item__svg {
      width: 100%;
      height: 100%; }

.come-back {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 24px;
  border: 1px solid var(--main);
  border-radius: 4px;
  transition: all ease 400ms; }
  .come-back:hover .come-back__title {
    color: var(--main-darker); }
  .come-back:active {
    background-color: var(--main); }
    .come-back:active .come-back__title {
      color: #fff; }
    .come-back:active .come-back__svg {
      fill: #fff; }
  .come-back:focus {
    background-color: var(--main-lighter); }
  .come-back_disable {
    pointer-events: none;
    border-color: #B6B6B6; }
    .come-back_disable .come-back__title {
      color: #B6B6B6; }
  .come-back__svg {
    width: 13px;
    height: 13px;
    margin-right: 13px;
    fill: #B6B6B6; }
  .come-back__title {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: #333333; }

.text-line {
  display: flex; }
  .text-line__item {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px; }
  .text-line__ribbon {
    flex-grow: 1;
    border-bottom: 1px dashed #B6B6B6;
    margin: 0 10px;
    margin-bottom: 5px; }

.btn-remove {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #ED6262; }

.btn-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  background-color: rgba(234, 234, 234, 0.5); }
  .btn-filter__svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    fill: #929292; }
  .btn-filter__title {
    font-style: normal;
    font-weight: bold;
    color: #929292;
    font-size: 15px;
    line-height: 22px; }

.btn-menu {
  display: flex;
  align-items: center;
  justify-content: center; }
  .btn-menu_mod-1 {
    width: 100%;
    height: 54px; }
  .btn-menu__title {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    margin-right: 10px;
    color: #fff; }
  .btn-menu__svg {
    width: 15px;
    height: 13px;
    transform: rotate(-90deg);
    fill: #fff; }

.block-alert {
  display: flex;
  padding: 25px 30px;
  border: 1px dashed var(--main);
  border-radius: 4px; }
  .block-alert_mod {
    width: fit-content;
    flex-direction: column;
    padding: 20px 30px;
    background-color: #F8F8F8;
    border: 1px dashed #B6B6B6; }
  .block-alert__svg {
    fill: var(--main);
    width: 42px;
    height: 42px; }
  .block-alert__text {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px; }
    .block-alert__text b {
      color: var(--main); }
    .block-alert__text_small {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px; }
    .block-alert__text_bold {
      font-weight: bold; }

.item-warning {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: #FFD98E;
  z-index: 1;
  border-radius: 50%; }
  .item-warning:hover .item-warning__inner {
    opacity: 1;
    visibility: visible; }
  .item-warning__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%; }
    .item-warning__wrapper::before {
      content: '!';
      margin: auto; }
  .item-warning__inner {
    display: block;
    position: absolute;
    top: calc(100% + 8px);
    transform: translateX(-50%);
    left: 11px;
    width: 180px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    background-color: #fff;
    z-index: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    transition: all ease 400ms;
    opacity: 0;
    visibility: hidden;
    color: #333333;
    padding: 15px 18px; }
    .item-warning__inner::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 0 5px 8px 5px;
      border-color: transparent transparent var(--main) transparent; }

.block-contact-item {
  display: flex; }
  .block-contact-item:not(:last-child) {
    margin-bottom: 25px; }
  .block-contact-item__wrapper {
    display: flex;
    flex-direction: column; }
  .block-contact-item__title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px; }
    .block-contact-item__title_bold {
      font-weight: bold; }
  .block-contact-item__svg {
    fill: var(--main);
    margin-top: 2px;
    margin-right: 15px;
    flex-shrink: 0;
    width: 20px;
    height: 20px; }

.block-address {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px; }

.region-select__title {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px; }

.region-select__header {
  margin-bottom: 35px; }

.region-select__item:not(:last-child) {
  margin-bottom: 32px; }

.region-place {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 20px 20px 20px 30px; }
  .region-place__title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px; }
    @media (max-width: 575px) {
      .region-place__title {
        order: 1; } }
  .region-place__svg {
    fill: var(--main);
    width: 20px;
    height: 20px; }
  .region-place__info {
    display: flex;
    align-items: center;
    flex-shrink: 0; }
    @media (min-width: 576px) {
      .region-place__info:first-child {
        margin-right: 10px; } }
    @media (max-width: 575px) {
      .region-place__info:first-child {
        margin-bottom: 5px; } }
    .region-place__info > *:first-child {
      margin-right: 7px; }
    .region-place__info-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px; }
  .region-place__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px; }
    @media (max-width: 575px) {
      .region-place__top {
        flex-direction: column; } }
  .region-place__bottom {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 576px) {
      .region-place__bottom {
        margin-right: 30%; } }
    @media (max-width: 575px) {
      .region-place__bottom {
        flex-direction: column; } }
  .region-place .show-map {
    flex-shrink: 0; }
    @media (max-width: 575px) {
      .region-place .show-map {
        margin-bottom: 10px; } }

.show-map {
  display: flex; }
  .show-map__title {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    text-decoration-line: underline;
    color: var(--main); }

.select-drop {
  position: relative; }
  .select-drop_active .select-drop__svg {
    transform: translateY(-50%) rotate(180deg); }
  .select-drop__svg {
    position: absolute;
    pointer-events: none;
    width: 12px;
    height: 8px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    fill: #929292;
    transition: 0.35s; }
  .select-drop__value {
    color: #B6B6B6; }
  .select-drop__dropout {
    position: absolute;
    width: 100%;
    top: calc(100% + 1px);
    padding: 15px;
    z-index: 5; }
  .select-drop__option {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    display: block; }
  .select-drop__select {
    cursor: pointer; }
  .select-drop__input {
    pointer-events: none; }

.list-menu {
  display: flex;
  flex-direction: column;
  transition: all ease 400ms; }
  @media (max-width: 1199px) {
    .list-menu {
      position: fixed;
      width: 100%;
      height: calc(100vh - 60px);
      top: 60px;
      left: 0;
      z-index: 5;
      background-color: #fff;
      transform: translateX(100%);
      overflow: overlay; } }
  .list-menu__wrapper {
    display: flex;
    flex-direction: column; }
    @media (max-width: 1199px) {
      .list-menu__wrapper {
        height: fit-content; } }
    @media (min-width: 1200px) {
      .list-menu__wrapper > *:nth-child(2) {
        border-top: 1px solid #EAEAEA;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px; } }
    .list-menu__wrapper > *:nth-child(2).active {
      border-top: 1px solid var(--main); }
    @media (min-width: 1200px) {
      .list-menu__wrapper > *:last-child {
        border-bottom: 1px solid #EAEAEA;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px; } }
    .list-menu__wrapper > *:last-child.active {
      border-bottom: 1px solid var(--main); }
  .list-menu.active {
    transform: none; }
  .list-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all ease 0.35s; }
    .list-menu-item:hover {
      background-color: #EBF7F6; }
    @media (min-width: 1200px) {
      .list-menu-item.active .list-menu-item__title {
        color: #fff;
        font-weight: bold; } }
    .list-menu-item.active .list-menu-item__svg {
      fill: #fff; }
    @media (min-width: 1200px) {
      .list-menu-item.active {
        background-color: var(--main);
        border-left: 1px solid var(--main);
        border-right: 1px solid var(--main); } }
    @media (max-width: 1199px) {
      .list-menu-item.active {
        background-color: #D6EFEE; } }
    .list-menu-item__title {
      color: #333333;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px; }
    .list-menu-item__svg {
      width: 12px;
      height: 8px;
      transform: rotate(-90deg);
      transition: 0.35s; }
      @media (max-width: 1199px) {
        .list-menu-item__svg {
          display: none; } }
    @media (min-width: 1200px) {
      .list-menu-item {
        padding: 17px;
        border-left: 1px solid #EAEAEA;
        border-right: 1px solid #EAEAEA; } }
    @media (max-width: 1199px) {
      .list-menu-item {
        height: 56px;
        padding: 0 30px; }
        .list-menu-item:not(:last-child) {
          border-bottom: 1px solid #eaeaea; }
        .list-menu-item:first-child {
          border-top: none;
          border-radius: 0; }
        .list-menu-item:last-child {
          background-color: #EAEAEA; }
          .list-menu-item:last-child .list-menu-item__title {
            color: #929292; } }
  .list-menu-back {
    display: flex;
    align-items: center;
    background-color: var(--main);
    height: 44px;
    padding: 0 20px;
    cursor: pointer; }
    @media (min-width: 1200px) {
      .list-menu-back {
        display: none; } }
    .list-menu-back__svg {
      width: 15px;
      height: 12px;
      margin-right: 10px;
      transform: rotate(90deg);
      fill: #fff; }
    .list-menu-back__title {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: #fff; }

@media (max-width: 767px) {
  .list-order {
    overflow: overlay; } }

.list-order__wrapper {
  display: flex;
  flex-direction: column;
  min-width: 735px; }
  .list-order__wrapper > *:last-child {
    border-bottom: 1px solid #EAEAEA; }

.list-order-item {
  border: 1px solid #EAEAEA;
  border-bottom: none;
  transition: all ease 350ms; }
  .list-order-item.error .list-order-item__svg-status, .list-order-item.success .list-order-item__svg-status {
    width: 20px;
    height: 14px; }
  .list-order-item.active {
    border-color: var(--main);
    border-bottom: 1px solid var(--main); }
    .list-order-item.active + * {
      border-top: none; }
    .list-order-item.active .list-order-item__header {
      border-bottom: 1px solid #EAEAEA; }
    .list-order-item.active .list-order-item__svg-arrow {
      transform: rotate(-180deg); }
  .list-order-item.processing .list-order-item__svg-status {
    fill: #FFD98E; }
  .list-order-item.processing .list-order-item__status {
    color: #FFD98E; }
  .list-order-item.error .list-order-item__svg-status {
    fill: #ED6262; }
  .list-order-item.error .list-order-item__status {
    color: #ED6262; }
  .list-order-item.success .list-order-item__svg-status {
    fill: #32B0A9; }
  .list-order-item.success .list-order-item__status {
    color: #32B0A9; }
  .list-order-item__wrapper-text {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: auto;
    margin-right: 22px; }
  .list-order-item__padding {
    padding: 0 23px; }
  .list-order-item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-right: 0px;
    height: 57px; }
  .list-order-item__drop {
    padding-top: 15px;
    padding-bottom: 20px; }
  .list-order-item__price {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px; }
  .list-order-item__title {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    margin-right: 10px; }
    .list-order-item__title-wrap {
      display: flex;
      align-items: center; }
  .list-order-item__svg-arrow {
    transition: all ease 350ms;
    width: 15px;
    height: 10px; }
  .list-order-item__svg-status {
    margin-right: 10px;
    width: 20px;
    height: 20px; }
  .list-order-item__status {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px; }
    .list-order-item__status-wrap {
      display: flex;
      align-items: center;
      height: 100%;
      border-left: 1px solid #EAEAEA;
      padding-left: 23px;
      width: 162px; }
  .list-order-item__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: auto;
    width: 170px;
    height: 44px; }
  .list-order-item .text-line:not(:nth-last-child(2)) {
    margin-bottom: 15px; }
  .list-order-item .text-line__after {
    font-size: 16px;
    line-height: 24px; }

.date-picker {
  position: relative;
  max-width: 192px; }
  .date-picker__svg {
    position: absolute;
    transition: all ease 300ms;
    top: 50%;
    transform: translateY(-50%); }
    .date-picker__svg_calendar {
      fill: var(--main);
      left: 18px;
      width: 24px;
      height: 24px; }
    .date-picker__svg_arrow {
      right: 12px;
      fill: #929292;
      width: 13px;
      height: 12px; }
  .date-picker__input {
    position: relative;
    padding: 10px 15px 10px 49px;
    max-width: 100%;
    background: transparent;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #929292;
    cursor: default; }
    .date-picker__input:focus + svg {
      transform: translateY(-50%) rotate(180deg); }

.control-ribbon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
  border-bottom: 1px solid #EAEAEA; }
  @media (max-width: 991px) {
    .control-ribbon__sort {
      flex-direction: column;
      height: fit-content !important; }
      .control-ribbon__sort .block-tab-sort__grid {
        margin-top: 15px;
        flex-direction: column; }
        .control-ribbon__sort .block-tab-sort__grid .tab-item-sort {
          padding: 6px 14px; }
          .control-ribbon__sort .block-tab-sort__grid .tab-item-sort:not(:last-child) {
            margin-bottom: 15px; }
      .control-ribbon__sort .toggle-view {
        display: none; } }

.control-count {
  display: flex; }
  .control-count_bonus {
    align-items: center; }
    .control-count_bonus .control-count-number {
      margin-left: 10px; }
    .control-count_bonus .control-count__subtitle {
      margin-left: 25px; }
  .control-count__title {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px; }
  .control-count-number {
    display: flex;
    align-items: center;
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--main);
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    height: 30px;
    padding: 0 10px; }
  .control-count__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #929292; }

.award {
  display: flex;
  position: relative;
  max-width: 100%;
  width: 560px;
  justify-content: space-between; }
  @media (max-width: 575px) {
    .award {
      flex-direction: column;
      align-items: center; } }
  .award-line {
    position: absolute;
    top: 37px;
    left: 50%;
    width: 60%;
    height: 3px;
    background-color: rgba(50, 176, 169, 0.2);
    transform: translateX(-50%); }
    @media (max-width: 575px) {
      .award-line {
        display: none; } }
  .award-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px; }
    @media (max-width: 575px) {
      .award-item:not(:last-child) {
        margin-bottom: 25px; } }
    .award-item__img {
      display: block;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%; }
      .award-item__img-wrap {
        display: flex;
        width: 80px;
        height: 80px;
        z-index: 1;
        background-color: #fff;
        margin-bottom: 30px; }
    .award-item__title {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px; }

.notify-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 40px;
  border: 1px solid #EAEAEA;
  border-radius: 4px; }
  @media (max-width: 991px) {
    .notify-block {
      flex-direction: column; }
      .notify-block > *:not(:last-child) {
        margin-bottom: 20px; } }
  @media (max-width: 575px) {
    .notify-block {
      padding: 20px; } }
  .notify-block_mail .notify-block__svg {
    fill: var(--main); }
  .notify-block_order .notify-block__svg {
    fill: var(--main); }
  .notify-block__svg {
    fill: #DBDBDB;
    width: 48px;
    height: 48px; }
    @media (min-width: 992px) {
      .notify-block__svg {
        align-self: flex-start; } }
  .notify-block__text {
    display: flex;
    flex-direction: column;
    max-width: 485px; }
    @media (max-width: 991px) {
      .notify-block__text {
        text-align: center; } }
    @media (min-width: 992px) {
      .notify-block__text {
        margin-left: 40px;
        margin-right: auto; } }
  .notify-block__title {
    display: block;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px; }
  .notify-block__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #B6B6B6; }
  .notify-block__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 192px;
    height: 42px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px; }
    @media (max-width: 575px) {
      .notify-block__btn {
        width: 100%; } }
    .notify-block__btn_mod {
      min-width: 228px; }
  .notify-block--hide {
    display: none; }

.block-404 {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .block-404__stand {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%; }
    .block-404__stand-wrap {
      display: flex;
      max-height: 300px;
      max-width: 640px;
      margin-bottom: 35px; }
  .block-404__title {
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 54px;
    color: var(--main);
    margin-bottom: 20px; }
    @media (max-width: 1919px) {
      .block-404__title {
        font-size: calc(0.00875 * 100vw + 25.2px);
        line-height: calc(0.0125 * 100vw + 30px); } }
  .block-404__subtitle {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 40px; }
  .block-404__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 238px;
    height: 46px; }

.tab-type {
  display: flex;
  width: 100%;
  background-color: #F4F4F4;
  border-radius: 2px; }
  @media (min-width: 576px) {
    .tab-type_compare {
      height: 46px; } }
  @media (max-width: 575px) {
    .tab-type_compare {
      padding: 20px;
      flex-direction: column; } }
  .tab-type-item {
    display: flex;
    align-items: center;
    position: relative; }
    @media (max-width: 575px) {
      .tab-type-item {
        width: fit-content; }
        .tab-type-item:not(:last-child) {
          margin-bottom: 20px; } }
    @media (min-width: 576px) {
      .tab-type-item {
        padding: 0 20px; }
        .tab-type-item:not(:last-child) {
          margin-right: 15px; } }
    .tab-type-item__title {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #A2A2A2; }
    .tab-type-item::after {
      content: '';
      position: absolute;
      background-color: var(--main);
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      opacity: 0;
      visibility: hidden; }
    .tab-type-item.active::after {
      opacity: 1;
      visibility: visible; }

.popup-product {
  height: 640px; }
  .popup-product__body {
    height: calc(100% - 65px);
    display: flex;
    padding-top: 15px;
    padding-bottom: 50px;
    padding-left: 25px;
    padding-right: 45px; }
    .popup-product__body > *:first-child {
      width: 390px;
      margin-right: 30px; }
  .popup-product__slider {
    height: 320px; }
  .popup-product__close {
    position: relative; }
  .popup-product__header {
    padding: 0 25px;
    padding-top: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EAEAEA; }
  .popup-product .article-slider {
    height: fit-content; }
    .popup-product .article-slider__view {
      height: 245px; }
    .popup-product .article-slider__nav {
      height: 95px; }
    .popup-product .article-slider-arrow {
      bottom: 0; }
      .popup-product .article-slider-arrow_prev {
        left: -15px; }
      .popup-product .article-slider-arrow_next {
        right: -15px; }
    .popup-product .article-slider__item_nav {
      width: 80px !important;
      height: 70px !important; }
    .popup-product .article-slider .slick-center {
      padding: 5px;
      transform: translateY(-5px); }

.dropdown-search__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px; }

.dropdown-search__result {
  padding-right: 30px;
  max-height: 250px; }
  .dropdown-search__result-art {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 8px; }
  .dropdown-search__result-cat {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 12px; }
  .dropdown-search__result .simplebar-content {
    display: flex;
    flex-direction: column; }
    .dropdown-search__result .simplebar-content .highlight {
      color: var(--main); }

.state-component--1.visible {
  fill: var(--main); }

.state-component--2 {
  visibility: hidden; }
  .state-component--2.visible {
    visibility: visible; }

.hide-component.active .hide-component__state--1 {
  opacity: 0;
  visibility: hidden; }

.hide-component.active .hide-component__state--2 {
  opacity: 1;
  visibility: visible; }

.hide-component__state {
  transition: all ease 400ms; }
  .hide-component__state--2 {
    opacity: 0;
    visibility: hidden; }

.slider-narrow {
  position: relative;
  height: 300px; }
  @media (max-width: 1199px) {
    .slider-narrow {
      height: calc(0.14375 * 100vw + 24px); } }
  .slider-narrow__slide {
    display: flex;
    height: auto; }
  .slider-narrow__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto; }
    .slider-narrow__arrow--prev {
      left: 0px; }
    .slider-narrow__arrow--next {
      right: 0px; }
    @media (max-width: 1199px) {
      .slider-narrow__arrow {
        display: none; } }
  .slider-narrow__container {
    height: 100%; }
  .slider-narrow__pagination {
    position: relative;
    justify-content: flex-end;
    z-index: 1;
    margin-top: -20px;
    margin-right: 45px; }
  .slider-narrow .swiper-wrapper {
    height: 100%; }

.categories-popular {
  height: fit-content; }
  .categories-popular__wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px; }
    @media (max-width: 1199px) {
      .categories-popular__wrapper {
        grid-template-columns: repeat(6, 1fr); } }
    @media (max-width: 767px) {
      .categories-popular__wrapper {
        grid-template-columns: repeat(4, 1fr); } }
    @media (max-width: 575px) {
      .categories-popular__wrapper {
        grid-template-columns: repeat(2, 1fr); } }

.categories-popular-card {
  height: 50px;
  background: #FFFFFF;
  border-radius: 4px;
  transition: all ease 400ms;
  padding: 8px 15px; }
  .categories-popular-card:hover {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08); }
  .categories-popular-card__wrapper {
    display: flex; }
  .categories-popular-card__image {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%; }

.margin-small {
  margin-bottom: 10px; }

.svg-arrow-remind__cup {
  transform: translateX(1.7px);
  fill: white; }

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07); }
  .header__delimeter {
    border-bottom: 1px solid #EAEAEA; }
  .header__slogan {
    font-size: 12px;
    line-height: 1.33;
    color: #b6b6b6; }
    .header__slogan--offset-left {
      margin-left: 37px; }
  .header__logo {
    display: flex;
    max-width: 153px;
    max-height: 38px; }
    .header__logo-icon {
      object-fit: contain;
      max-height: 100%;
      max-width: 100%; }
  .header__contacts-right {
    margin-left: auto; }
  .header__mail-icon {
    width: 14px;
    height: 12px; }
  .header__mail-value {
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.5;
    text-decoration: underline; }
  .header__tel-icon {
    width: 12px;
    height: 12px; }
  .header__tel-value {
    vertical-align: middle;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 700; }
  .header__contact-icon {
    margin-right: 9px;
    fill: #333; }
  .header__contact:hover .header__contact-icon {
    fill: var(--main); }
  .header__contact:not(:first-child) {
    margin-left: 32px; }
  .header__bottom {
    padding: 15px 0; }
  .header__search {
    position: relative; }
    .header__search-input {
      border-radius: 0 4px 4px 0; }
    .header__search-btn {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 60px;
      background: transparent;
      border: 0;
      cursor: pointer; }
    .header__search-icon {
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      fill: var(--main); }
      .header__search-icon--text {
        fill: #333; }
    .header__search--width-1 {
      width: 507px; }
    .header__search--width-2 {
      width: 450px; }
    .header__search--width-3 {
      width: 591px; }
  .header__search-input input:focus {
    border-color: var(--main); }
    .header__search-input input:focus ~ .dropdown-search {
      opacity: 1;
      visibility: visible; }
  .header__dropdown {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: 0.35s;
    top: calc(100% + 5px);
    background-color: #fff;
    width: 100%;
    padding: 10px 15px;
    box-shadow: 0px 14px 17px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid #EAEAEA; }

.header-city {
  cursor: pointer; }
  .header-city__value {
    font-size: 13px;
    line-height: 1.2; }
  .header-city__arrow {
    width: 10px;
    height: 6px;
    margin-left: 9px;
    fill: #333; }
  .header-city:hover .header-city__dropdown {
    transform: none;
    opacity: 1;
    pointer-events: all; }

.header-catalog-btn {
  padding: 12px 16px;
  font-size: 15px;
  font-weight: normal;
  border-radius: 4px 0 0 4px;
  transition: border-radius 0.35s; }
  .header-catalog-btn__arr {
    width: 12px;
    height: 8px;
    margin-left: 27px;
    transition: transform 0.35s; }
  .header-catalog-btn--filled .header-catalog-btn__arr {
    fill: #fff; }
  .header-catalog-btn--transparent {
    border: 1px solid #EAEAEA;
    border-right: 0; }
    .header-catalog-btn--transparent:hover {
      color: #333; }
  .header-catalog-btn:hover {
    border-radius: 0;
    box-shadow: none; }
    .header-catalog-btn:hover .header-catalog-btn__arr {
      transform: rotate(180deg); }
    .header-catalog-btn:hover ~ .catalog-menu {
      opacity: 1;
      pointer-events: all; }

.header-menu__link {
  position: relative;
  padding: 22px 0;
  font-size: 14px;
  line-height: 22px; }
  .header-menu__link::after {
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--main);
    opacity: 0;
    border-radius: 2px;
    transition: opacity 0.35s; }
  .header-menu__link:hover {
    color: #333; }
    .header-menu__link:hover::after {
      opacity: 1; }
  .header-menu__link--offset:not(:last-child) {
    margin-right: 30px; }

.header-action {
  font-size: 14px;
  line-height: 1; }
  .header-action__icon {
    width: 24px;
    height: 24px; }
    .header-action__icon-wrap {
      position: relative; }
    .header-action__icon--filled {
      fill: var(--main); }
  .header-action__text {
    margin-left: 13px; }
  .header-action__count {
    width: calc(0.00125 * 100vw + 19.6px);
    height: calc(0.00125 * 100vw + 19.6px);
    position: absolute;
    left: 55%;
    bottom: 55%;
    font-size: calc(0.00125 * 100vw + 11.6px);
    line-height: 1;
    font-weight: 500;
    background-color: var(--additional);
    border-radius: 50%;
    transition: background-color 0.35s; }
    .header-action__count--main {
      color: #ffffff;
      background: var(--main); }
  .header-action--offset {
    margin-left: 30px; }
  .header-action:hover .header-action__count {
    color: #fff;
    background-color: var(--main); }
  .header-action:hover svg {
    fill: var(--main); }
  .header-action:active svg {
    fill: var(--main-darker); }
  .header-action_disable {
    pointer-events: none;
    opacity: 0.8; }
    .header-action_disable svg {
      fill: #B6B6B6; }
    .header-action_disable span {
      color: #B6B6B6; }

.header-cart-btn {
  padding: 0 19px; }
  .header-cart-btn__icon {
    width: 24px;
    height: 24px;
    margin-right: 9px;
    fill: #fff; }
  .header-cart-btn__text {
    margin-left: 12px;
    font-size: 14px;
    line-height: 1;
    font-weight: 700; }
  .header-cart-btn--simple {
    padding: 0 0 0 16px; }
  .header-cart-btn--border {
    position: relative; }
    .header-cart-btn--border::before {
      content: "";
      width: 1px;
      height: 23px;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      background: #B6B6B6; }
  .header-cart-btn:hover .header-action__count {
    color: #fff;
    background-color: var(--main); }

.header-burger {
  width: 20px;
  height: 14px; }
  .header-burger__line {
    background-color: #333; }
    .header-burger__line:first-child {
      transform: translateY(-5px); }
    .header-burger__line:last-child {
      transform: translateY(5px); }

.header-mobile {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07); }
  .header-mobile__content {
    padding: 14px 28px 14px 24px; }
  .header-mobile__tel-svg {
    width: 16px;
    height: 16px;
    margin: 0; }
  .header-mobile__wrapper-control {
    display: flex;
    align-items: center; }
    .header-mobile__wrapper-control > *:first-child {
      margin-right: 15px; }

.header-subline {
  background-color: var(--main);
  height: 34px; }
  .header-subline__wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%; }
  .header-subline__link {
    display: flex;
    align-items: center; }

.subline-link {
  display: flex;
  position: relative;
  color: #fff; }
  .subline-link:hover .subline-link__dropdown {
    opacity: 1;
    visibility: visible; }
  .subline-link:hover .subline-link__arrow {
    transform: rotate(180deg); }
  .subline-link__arrow {
    fill: #fff;
    margin-left: 7px; }
  .subline-link_dropdown {
    font-weight: bold; }
  .subline-link__dropdown {
    position: absolute;
    top: 100%;
    transition: all ease 400ms;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    box-shadow: 0px 14px 17px rgba(0, 0, 0, 0.04);
    border-radius: 4px; }
  .subline-link span {
    color: #fff; }

.subline-dropdown {
  background-color: #fff; }
  .subline-dropdown__wrapper {
    max-height: 200px;
    padding: 15px 20px; }
  .subline-dropdown__link {
    display: flex;
    width: 100%;
    font-weight: 400; }
    .subline-dropdown__link:not(:last-child) {
      margin-bottom: 10px; }
  .subline-dropdown--width-content {
    width: fit-content;
    min-width: calc(100% + 50px); }
    .subline-dropdown--width-content .subline-dropdown__link {
      display: flex;
      white-space: nowrap; }
    .subline-dropdown--width-content .simplebar-wrapper {
      min-width: 100%; }
    .subline-dropdown--width-content .simplebar-mask {
      width: 100% !important; }
    .subline-dropdown--width-content .simplebar-offset {
      width: fit-content; }
    .subline-dropdown--width-content .simplebar-content {
      width: fit-content; }
    .subline-dropdown--width-content .simplebar-content-wrapper {
      max-width: fit-content; }
  .subline-dropdown .simplebar-vertical {
    margin: 15px 0;
    right: 10px; }

@media (min-width: 1200px) {
  .header-mobile {
    display: none; } }

@media (max-width: 1199px) {
  .header-desktop {
    display: none; }
  .header__logo {
    height: 32px; }
    .header__logo-icon {
      height: 100%; } }

.header-1__middle {
  padding: 12px 0 13px; }

.header-1__workhours {
  margin-left: 56px;
  font-size: 14px;
  line-height: 1.2;
  color: #929292; }
  .header-1__workhours-text {
    margin-right: 10px; }

.header-1__menu {
  margin-left: auto; }
  .header-1__menu-link {
    display: block;
    padding: 12px 0;
    color: #929292;
    font-size: 14px;
    line-height: 1.2; }
    .header-1__menu-link:hover {
      color: #929292;
      text-decoration: underline; }
  .header-1__menu-item {
    position: relative; }
    .header-1__menu-item:hover .header-1__menu-link {
      color: var(--main); }
    .header-1__menu-item:hover .header-1__menu-submenu {
      opacity: 1;
      pointer-events: all;
      transform: none; }
    .header-1__menu-item:hover::before, .header-1__menu-item:hover::after {
      pointer-events: all; }
    .header-1__menu-item::before, .header-1__menu-item::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 18px;
      pointer-events: none; }
    .header-1__menu-item::before {
      right: 100%; }
    .header-1__menu-item::after {
      left: 100%; }
  .header-1__menu-link:not(:first-child), .header-1__menu-item:not(:first-child) {
    margin-left: 32px; }
  .header-1__menu-submenu {
    position: absolute;
    top: 100%;
    left: -18px;
    padding: 16px 30px 17px 23px;
    min-width: 215px;
    transform: translateX(-6px);
    z-index: 1;
    background-color: #fff;
    opacity: 0;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.04);
    border-radius: 0 0 4px 4px;
    border: 1px solid #EAEAEA;
    pointer-events: none;
    transition: opacity 0.35s, transform 0.35s; }
    .header-1__menu-submenu-link {
      display: block;
      font-size: 14px;
      line-height: 1.3; }
      .header-1__menu-submenu-link:not(:last-child) {
        margin-bottom: 14px; }

.header-1__actions {
  max-width: 410px;
  padding: 0px 5px;
  margin: auto; }

.header-1 .header__search {
  margin-left: 20px; }

.header-2__actions {
  padding: 0 26px 0 20px; }

.header-3__city {
  margin-left: 57px; }

.header-3__menu {
  padding-left: 58px;
  justify-content: flex-end; }
  .header-3__menu > *:not(:last-child) {
    margin-right: 30px; }

.header-3__actions {
  padding: 0 20px 0 45px; }

.header-3__contacts {
  padding-left: 10px; }

.header-4__menu {
  padding: 0 60px;
  justify-content: flex-start; }
  .header-4__menu > *:not(:last-child) {
    margin-right: 15px; }

.header-4__search-btn {
  width: 122px;
  background: var(--main); }

.header-4__search-icon {
  fill: #fff; }

.header-4__logo {
  margin-right: 70px; }

.header-5__catalog-btn {
  margin-right: 15px;
  border-radius: 4px; }

.header-5__search-input {
  border-radius: 4px; }

.header-5__burger {
  width: 18px;
  height: 12px;
  margin-right: 14px; }
  .header-5__burger__line {
    background: #ffffff; }
    .header-5__burger__line:first-child {
      transform: translateY(-4px); }
    .header-5__burger__line:last-child {
      transform: translateY(4px); }

.header-6 .header__actions {
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  max-width: 445px;
  padding: 0 15px; }
  .header-6 .header__actions > *:not(:last-child) {
    margin-right: 10px; }

.header-6 .header-cart-btn {
  display: flex;
  flex-shrink: 0;
  width: fit-content; }

.header-6__search {
  width: 500px; }

.header-6__subline .subline-link:nth-of-type(n+5) {
  display: none; }

.footer--dark {
  color: #ffffff;
  background: #333333;
  --gray-main: #929292;
  --gray-alt: #B6B6B6;
  --contact-item-color: #929292;
  --contact-item-alt-color: #fff;
  --contact-item-icon-color: #929292;
  --menu-title-color: #fff;
  --info-color: #717171;
  --border-color: #484848; }

.footer--light {
  color: #333;
  --gray-main: #B6B6B6;
  --gray-alt: #929292;
  --contact-item-color: #333;
  --contact-item-alt-color: var(--main);
  --contact-item-icon-color: #333;
  --menu-title-color: #333;
  --info-color: #929292;
  --border-color: #EAEAEA; }

.footer__top {
  padding: 45px 0; }

.footer__bottom {
  padding: 21px 0;
  border-top: 1px solid var(--border-color); }

.footer-logo {
  display: block;
  margin-bottom: 30px; }
  .footer-logo__img {
    display: block;
    max-width: 100%; }

.footer__col {
  flex: 0 0 192px; }

.footer__contact-item:not(:last-child) {
  margin-bottom: calc(0.00625 * 100vw + 8px); }

.footer__contact-item--small-offset:not(:last-child) {
  margin-bottom: 8px; }

.footer__icons {
  margin-top: 30px; }
  .footer__icons--small-offset {
    margin-top: 16px; }

.footer__soc-icon:not(:last-child) {
  margin-right: 12px; }

.footer__menu-title {
  padding-bottom: 18px;
  color: var(--menu-title-color);
  font-size: 17px;
  line-height: 20px;
  font-weight: 700; }
  .footer__menu-title--border {
    margin-bottom: 13px;
    border-bottom: 1px solid #484848; }

.footer__menu-link {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-alt); }
  .footer__menu-link:not(:last-child) {
    margin-bottom: 8px; }

.footer__form-col {
  flex: 0 0 300px; }

.footer__form-title {
  margin-bottom: 6px;
  font-size: calc(0.00125 * 100vw + 17.6px);
  line-height: 26px;
  font-weight: 700; }

.footer__form-text {
  margin-bottom: 20px;
  font-size: calc(0.00125 * 100vw + 12.6px);
  line-height: calc(0.00187 * 100vw + 16.4px); }

.footer__personal {
  color: var(--gray-main); }

.footer__info {
  color: var(--info-color);
  font-size: 12px;
  line-height: 18px; }
  .footer__info--underline {
    text-decoration: underline; }
  .footer__info:not(:last-child) {
    margin-right: 30px; }

.footer__ps {
  margin-left: auto;
  color: var(--gray-main); }
  .footer__ps-icon {
    width: 100px;
    height: 20px;
    margin-left: 7px;
    fill: var(--gray-main); }
  .footer__ps:hover .footer__ps-icon {
    fill: var(--main); }

.footer__cards {
  max-width: 191px;
  max-height: 13px; }

@media (max-width: 1199px) {
  .footer__top {
    flex-wrap: wrap; }
  .footer__menus {
    flex-basis: 100%;
    flex-wrap: wrap;
    margin: 0 -15px 35px;
    padding: 0 15px 5px !important;
    border-bottom: 1px solid #484848; }
  .footer__bottom {
    margin: 0 -15px;
    padding-left: 15px;
    padding-right: 15px; }
  .footer__col {
    margin-bottom: 30px; }
  .footer__form-col {
    margin-bottom: 35px; }
  .footer__contacts-col {
    flex-basis: 100%;
    margin-bottom: 0 !important;
    order: 1; }
  .footer__icons {
    margin-top: 23px; } }

@media (max-width: 991px) {
  .footer__menu-col {
    flex-basis: 25%; }
  .footer__bottom {
    align-items: flex-start;
    flex-direction: column; }
  .footer__info:not(:last-child) {
    margin: 0 0 7px 0; }
  .footer__cards {
    margin: 24px 0; }
  .footer__ps {
    margin-left: 0; } }

@media (max-width: 767px) {
  .footer__col {
    flex-basis: 50%; } }

@media (min-width: 576px) and (max-width: 1199px) {
  .footer__menu-col {
    padding-right: 15px; } }

@media (max-width: 575px) {
  .footer__top, .footer__menus {
    flex-direction: column; }
  .footer__form-col, .footer__col, .footer__menus {
    flex-basis: auto !important; }
  .footer-logo {
    display: none; } }

.footer-1__menus {
  padding-left: 81px;
  padding-right: 98px; }

@media (max-width: 1199px) {
  .footer-1__menus {
    padding: 0; } }

.footer-2__menus {
  padding: 0 112px 0 152px; }

.footer-2__contacts-title {
  margin: calc(0.00937 * 100vw + 27px) 0 calc(0.005 * 100vw + 12.4px);
  font-size: 15px;
  line-height: 20px; }

@media (max-width: 1199px) {
  .footer-2__contacts-title {
    margin-top: 0; } }

.footer-3__menus {
  padding: 0 56px 0; }
  .footer-3__menus .footer__col {
    flex: auto; }

.footer-3__form-col {
  flex-basis: 370px; }

.footer-3__form-title__icon {
  width: 27px;
  height: 21px;
  margin-right: 18px;
  fill: var(--main); }

.btn {
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.35s, border-color 0.35s, background-color 0.35s, box-shadow 0.35s; }
  .btn--filled {
    color: #fff;
    background: var(--main);
    border: 1px solid var(--main); }
    .btn--filled:hover {
      color: #fff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }
    .btn--filled:active, .btn--filled:focus {
      background: var(--main-darker);
      border-color: var(--main-darker); }
    .btn--filled_disable {
      color: #929292;
      background: #EAEAEA;
      border-color: transparent; }
  .btn--filled-2 {
    color: var(--main);
    border: 1px solid var(--main); }
    .btn--filled-2:hover {
      color: var(--main-darker);
      border-color: var(--main-darker); }
    .btn--filled-2:active {
      color: #186F67;
      border-color: #186F67; }
    .btn--filled-2:focus {
      background-color: var(--main-lighter); }
    .btn--filled-2_disable {
      color: #B6B6B6;
      border-color: #B6B6B6; }
  .btn--filled-3 {
    color: #333333;
    border: 1px solid #333333; }
    .btn--filled-3:hover {
      color: var(--main);
      border-color: var(--main); }
    .btn--filled-3:active {
      color: var(--main-darker);
      border-color: var(--main-darker); }
    .btn--filled-3:focus {
      background-color: var(--main-lighter); }
    .btn--filled-3_disable {
      color: #B6B6B6;
      border-color: #B6B6B6; }
  .btn--empty {
    color: #929292;
    border-bottom: 1px dashed #B6B6B6; }
  .btn--white {
    color: var(--main);
    background: #ffffff; }
  .btn--auto-width {
    padding: 0 24px; }
  .btn--default-height {
    height: 42px; }

.form-input-wrap {
  position: relative; }
  .form-input-wrap.error .form-label {
    color: #ED6262; }

.form-text-input {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1.33;
  border: 1px solid #EAEAEA;
  border-radius: 4px; }
  .form-text-input:focus + .form-label, .form-text-input:not(:placeholder-shown):not(textarea) + .form-label {
    transform: translateY(-100%) scale(0.75); }
  .form-text-input::placeholder {
    color: transparent; }

.form-wrap {
  position: relative;
  height: fit-content; }
  .form-wrap--bg .preloader, .form-wrap--bg .mail-sent {
    background-color: #fff; }

.form-hiding-wrap--hide {
  opacity: 0;
  visibility: hidden; }

.form-elem-error {
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  font-size: 10px;
  color: #ED6262;
  line-height: 1.2; }
  .form-elem-error::first-letter {
    text-transform: capitalize; }

.form-label {
  height: 1em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  margin: auto;
  color: #929292;
  font-size: 15px;
  line-height: 1;
  transform-origin: left;
  pointer-events: none;
  transition: transform 0.35s; }
  .form-label.active {
    transform: translateY(-100%) scale(0.75); }

.personal {
  margin-top: calc(0.0025 * 100vw + 9.2px);
  font-size: 12px;
  line-height: 16px; }
  .personal__link {
    color: inherit;
    text-decoration: underline; }
    .personal__link:hover {
      color: inherit;
      text-decoration: none; }
  .personal--centered {
    text-align: center; }

.preloader, .mail-sent {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.preloader__figure {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 57px;
  height: 57px;
  margin: auto; }
  .preloader__figure--s {
    transform: scale(0.5); }
  .preloader__figure--m {
    transform: scale(0.79); }
  .preloader__figure--l {
    transform: scale(1); }

.preloader__item {
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: var(--main);
  border-radius: 9px;
  animation: bounce_circularG 1.1s infinite; }
  .preloader__item-1 {
    left: 0;
    top: 23px;
    animation-delay: 0.41s; }
  .preloader__item-2 {
    left: 6px;
    top: 6px;
    animation-delay: 0.55s; }
  .preloader__item-3 {
    top: 0;
    left: 23px;
    animation-delay: 0.69s; }
  .preloader__item-4 {
    right: 6px;
    top: 6px;
    animation-delay: 0.83s; }
  .preloader__item-5 {
    right: 0;
    top: 23px;
    animation-delay: 0.97s; }
  .preloader__item-6 {
    right: 6px;
    bottom: 6px;
    animation-delay: 1.1s; }
  .preloader__item-7 {
    left: 23px;
    bottom: 0;
    animation-delay: 1.24s; }
  .preloader__item-8 {
    left: 6px;
    bottom: 6px;
    animation-delay: 1.38s; }

@keyframes bounce_circularG {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.3); } }

.mail-sent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .mail-sent__title {
    font-size: 28px;
    line-height: 1.25;
    text-align: center;
    font-weight: 500; }
  .mail-sent__icon {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    fill: var(--main); }

.form-request__item {
  position: relative;
  border-radius: 4px; }

.form-request__input {
  display: block;
  width: 100%;
  height: 44px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1.33;
  border: 0;
  border-radius: 4px;
  border: 1px solid #eaeaea; }

.form-request__label {
  height: 1em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  margin: auto;
  color: #929292;
  font-size: 15px;
  line-height: 1;
  transform-origin: left;
  pointer-events: none;
  transition: transform 0.35s; }
  .form-request__label.active {
    transform: translateY(-100%) scale(0.75); }

.form-1_contact .form-1__title {
  margin-bottom: 12px; }

.form-1_contact .form-1__subtitle {
  margin-bottom: 25px; }

.form-1_contact .form-1__request {
  margin-bottom: 10px; }

.form-1_contact .form-request__wrapper {
  margin-bottom: 23px; }

.form-1_contact .form-request__submit {
  width: 100%; }

.form-1_contact-2 {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 30px 50px; }
  @media (max-width: 575px) {
    .form-1_contact-2 {
      padding: 20px; } }
  .form-1_contact-2 .form-1__title {
    margin-bottom: 12px; }
  .form-1_contact-2 .form-1__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 100%; }
    @media (min-width: 1200px) {
      .form-1_contact-2 .form-1__wrapper {
        align-items: center; } }
    @media (max-width: 1199px) {
      .form-1_contact-2 .form-1__wrapper {
        width: 440px;
        flex-direction: column;
        margin: auto; } }
  @media (min-width: 1200px) {
    .form-1_contact-2 .form-1__header {
      width: 360px;
      margin-right: 10px; } }
  .form-1_contact-2 .form-1__personal {
    width: 270px; }
    @media (max-width: 575px) {
      .form-1_contact-2 .form-1__personal {
        width: 100%; } }

@media (min-width: 1200px) and (max-width: 1919px) {
  .form-1_contact-2 .form-1__personal {
    width: calc(0.09722 * 100vw + 83.33333px); } }
  @media (max-width: 1199px) {
    .form-1_contact-2 .form-1__title {
      margin-bottom: 12px; }
    .form-1_contact-2 .form-1__subtitle {
      margin-bottom: 25px; }
    .form-1_contact-2 .form-1__request {
      margin-bottom: 10px; }
    .form-1_contact-2 .form-1__wrapper {
      margin-bottom: 23px; } }
  .form-1_contact-2 .form-request {
    display: flex; }
    @media (min-width: 1200px) {
      .form-1_contact-2 .form-request {
        width: 510px; } }
    @media (max-width: 575px) {
      .form-1_contact-2 .form-request {
        flex-direction: column; }
        .form-1_contact-2 .form-request__wrapper {
          margin-bottom: 23px; } }
    .form-1_contact-2 .form-request__wrapper {
      margin-right: 27px;
      width: 250px; }

@media (min-width: 1200px) and (max-width: 1919px) {
  .form-1_contact-2 .form-request__wrapper {
    width: calc(0.09722 * 100vw + 63.33333px); } }
    @media (min-width: 1200px) {
      .form-1_contact-2 .form-request__submit {
        width: 235px; } }
    @media (max-width: 1199px) {
      .form-1_contact-2 .form-request > * {
        width: 100%; } }

.form-1_contact-max {
  width: 100%; }

.form-1_contact-fix {
  width: 416px; }

.form-1__title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px; }

.form-1__subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #929292; }

.form-1__personal {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #929292; }

.form-1__link {
  text-decoration-line: underline;
  color: #929292; }

.form-1__request .form-request__submit {
  height: 44px; }

.form-personal__wrapper {
  margin-bottom: 20px; }

.form-personal__submit {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  height: 44px; }

.form-personal__personal {
  display: block;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #AAAAAA; }
  .form-personal__personal-link {
    color: #AAAAAA;
    text-decoration: underline; }

.form-personal__control {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; }
  .form-personal__control a {
    color: #929292;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px; }
    .form-personal__control a:hover {
      color: var(--main); }

.form-personal-item:not(:last-child) {
  margin-bottom: 15px; }

.form-personal .recaptcha {
  margin-top: 20px; }

.form-2__wrapper {
  display: flex; }

.form-2-item {
  position: relative; }
  .form-2-item_textarea .form-label {
    display: flex;
    align-items: center;
    left: 15px;
    bottom: auto;
    height: 44px; }
  .form-2-item_textarea .form-text-input {
    padding-top: 15px; }
    .form-2-item_textarea .form-text-input:focus + .form-label, .form-2-item_textarea .form-text-input:not(:placeholder-shown) + .form-label {
      transform: translateY(-32%) scale(0.75); }
  .form-2-item__title {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 8px; }
  .form-2-item__warning {
    display: block;
    position: absolute;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px; }
  .form-2-item__svg {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    cursor: pointer; }
  .form-2-item__svg-clarity {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 15px;
    top: 21px;
    transform: translateY(-50%); }
    .form-2-item__svg-clarity path {
      fill: #717171; }
  .form-2-item .form-request__label {
    color: #B6B6B6; }
  .form-2-item .form-request__input {
    min-height: 44px; }
  .form-2-item textarea {
    width: 100% !important; }
  .form-2-item.error {
    margin-bottom: 30px; }
    .form-2-item.error .form-2-item__title {
      color: #ED6262; }
    .form-2-item.error .form-2-item__warning {
      color: #ED6262; }
    .form-2-item.error .form-2-item__svg {
      opacity: 1;
      visibility: visible;
      fill: #ED6262; }
    .form-2-item.error .form-2-item__warning {
      color: #ED6262;
      transform: none;
      opacity: 1;
      visibility: visible; }
    .form-2-item.error .form-request__item {
      border-color: #ED6262; }

.lg-form {
  position: relative;
  padding: 48px 0; }
  .lg-form__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    filter: brightness(50%);
    object-fit: cover; }
  .lg-form__content {
    position: relative;
    z-index: 1;
    max-width: 500px;
    margin: auto;
    padding: 0 15px; }
    .lg-form__content--title {
      font-weight: 700;
      font-size: calc(0.0075 * 100vw + 21.6px);
      line-height: calc(0.00875 * 100vw + 25.2px);
      text-align: center;
      color: #FFFFFF;
      padding: 0 0 9px; }
    .lg-form__content--desc {
      font-size: calc(0.00125 * 100vw + 13.6px);
      line-height: calc(0.00375 * 100vw + 16.8px);
      text-align: center;
      color: #FFFFFF; }
    .lg-form__content .form-wrap {
      width: 370px;
      margin: 18px auto 0;
      text-align: center; }
  .lg-form__input {
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    height: 55px;
    width: 100%;
    position: relative;
    margin: 0 0 14px;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 15px 20px; }
    .lg-form__input::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF; }
  .lg-form__submit {
    background: var(--main);
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    height: 55px;
    width: 100%;
    margin: 0 0 8px;
    border: none;
    cursor: pointer; }
  .lg-form__personal {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5); }
    .lg-form__personal--link {
      text-decoration: underline; }
      .lg-form__personal--link:hover {
        text-decoration: none; }
  .lg-form .preloader__item {
    background-color: #fff; }
  .lg-form .mail-sent__title {
    color: #fff; }
  .lg-form .mail-sent__icon {
    fill: #fff; }

@media (max-width: 490px) {
  .lg-form__content .form-wrap {
    width: 100%; } }

.burger {
  position: relative;
  cursor: pointer; }
  .burger__line {
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto; }
    .burger__line--cross {
      top: 0;
      bottom: 0;
      margin: auto; }
  .burger--opened .burger__line:first-child, .burger--opened .burger__line:last-child {
    opacity: 0;
    transition: transform 0.4s, opacity 0s 0.4s; }
  .burger--opened .burger__line:first-child, .burger--opened .burger__line:last-child {
    transform: none; }
  .burger--opened .burger__line:nth-child(2) {
    transform: rotate(45deg); }
  .burger--opened .burger__line:nth-child(3) {
    transform: rotate(-45deg); }
  .burger--opened .burger__line--cross {
    transition: transform 0.4s 0.4s; }
  .burger--closed .burger__line--cross {
    transition: transform 0.4s; }
  .burger--closed .burger__line:first-child, .burger--closed .burger__line:last-child {
    transition: transform 0.4s 0.4s, opacity 0s 0.4s; }

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; }
  .modal.is-open {
    display: block; }
  .modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(51, 51, 51, 0.25);
    overflow: auto; }
  .modal__close {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer; }
    .modal__close svg {
      display: block;
      width: 14px;
      height: 14px;
      fill: #333;
      pointer-events: none; }
    .modal__close:hover svg {
      fill: var(--main); }
  .modal__container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden; }
  .modal__personal {
    margin-top: 10px;
    color: #c0c0c0; }
  .modal__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.2; }
  .modal--default-animation[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .modal--default-animation[aria-hidden="false"] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .modal--default-animation[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .modal--default-animation[aria-hidden="true"] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .modal--alt-animation[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn 0.35s cubic-bezier(0, 0, 0.2, 1); }
  .modal--alt-animation[aria-hidden="false"] .modal-container {
    animation: slideFromRight 0.35s cubic-bezier(0, 0, 0.2, 1); }
  .modal--alt-animation[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut 0.35s cubic-bezier(0, 0, 0.2, 1); }
  .modal--alt-animation[aria-hidden="true"] .modal-container {
    animation: slideToRight 0.35s cubic-bezier(0, 0, 0.2, 1); }

.modal-buy {
  z-index: 11; }
  .modal-buy__container {
    max-width: 340px;
    padding: 40px; }
  .modal-buy__btn {
    width: 100%;
    height: 42px; }
  .modal-buy .form-2-item {
    margin-bottom: 20px; }

@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from {
    transform: translateY(15%); }
  to {
    transform: translateY(0); } }

@keyframes mmslideOut {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-10%); } }

@keyframes slideFromRight {
  from {
    transform: translateX(2%); }
  to {
    transform: translateX(0); } }

@keyframes slideToRight {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(2%); } }

@media (max-width: 575px) {
  .modal__container {
    width: 90%; }
  .modal__close {
    top: 15px;
    right: 15px; } }

.modal-cities__container {
  max-width: 530px;
  padding: 40px; }

.modal-cities__title {
  margin-bottom: 30px; }

.modal-cities__list {
  max-height: calc(0.025 * 100vw + 207px); }
  .modal-cities__list-item {
    display: block;
    padding-right: 10px;
    font-size: calc(0.00125 * 100vw + 13.6px);
    line-height: 1.5; }
    .modal-cities__list-item:not(:first-child) {
      margin-top: calc(0.0025 * 100vw + 10.2px); }

.modal-cities__search {
  margin-bottom: calc(0.00313 * 100vw + 14px); }
  .modal-cities__search-btn {
    padding: 0 calc(0.00375 * 100vw + 16.8px);
    border-radius: 0 4px 4px 0; }
  .modal-cities__search-input {
    border-radius: 4px 0 0 4px; }

@media (max-width: 575px) {
  .modal-cities__container {
    padding: 28px 20px; }
  .modal-cities__title {
    margin-bottom: 18px;
    font-size: 18px; } }

.modal-product__container {
  max-width: 1100px; }

.custom-scrollbar .simplebar-track {
  background-color: #EAEAEA; }
  .custom-scrollbar .simplebar-track.simplebar-vertical {
    width: 2px; }
    .custom-scrollbar .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
      top: 0;
      bottom: 0; }

.custom-scrollbar .simplebar-scrollbar::before {
  left: 0;
  right: 0;
  background-color: var(--main);
  opacity: 1 !important;
  border-radius: 0; }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  width: 100%;
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none; }

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0; }

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px; }

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear; }

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll; }

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none; }

.catalog-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: grid;
  grid-template-areas: "left submenu";
  grid-template-columns: 304px 1fr;
  grid-template-rows: repeat(999, auto);
  background-color: #fff;
  opacity: 0;
  box-shadow: 0px 14px 17px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEAEA;
  border-radius: 0 4px 4px 4px;
  pointer-events: none;
  transition: all ease 400ms; }
  .catalog-menu-wrap {
    position: relative;
    z-index: 1; }
  .catalog-menu__item {
    position: relative;
    border-right: 1px solid #EAEAEA; }
    .catalog-menu__item:hover + .catalog-menu__submenu {
      height: auto;
      padding: 50px 90px 20px 90px;
      opacity: 1; }
  .catalog-menu__link {
    display: block;
    padding: 15px 42px 15px 23px;
    position: relative;
    transition: color 0.35s, background-color 0.35s; }
    .catalog-menu__link-arr {
      width: 10px;
      height: 6px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 26px;
      margin: auto;
      transform: rotate(-90deg); }
    .catalog-menu__link:hover {
      color: #fff;
      background: var(--main); }
      .catalog-menu__link:hover .catalog-menu__link-arr {
        fill: #fff; }
  .catalog-menu__submenu {
    height: 0;
    padding: 0;
    margin-left: -1px;
    grid-area: submenu;
    grid-row-end: span submenu;
    opacity: 0;
    overflow: hidden;
    border-left: 1px solid #EAEAEA; }
    .catalog-menu__submenu-item {
      flex-basis: 26%;
      margin-bottom: 30px; }
    .catalog-menu__submenu-link {
      display: block;
      font-size: 15px;
      line-height: 20px;
      font-weight: 500; }
    .catalog-menu__submenu:hover {
      height: auto;
      padding: 50px 90px 20px 90px;
      opacity: 1; }
  .catalog-menu__subcategories {
    padding-left: 20px;
    margin-top: 14px; }
    .catalog-menu__subcategories-link {
      display: block;
      font-size: 14px;
      line-height: 18px;
      color: #929292; }
      .catalog-menu__subcategories-link:not(:last-child) {
        margin-bottom: 10px; }
      .catalog-menu__subcategories-link:nth-child(n+4) {
        display: none; }
    .catalog-menu__subcategories-show-all {
      margin-top: 13px;
      font-size: 15px;
      line-height: 1;
      font-weight: 700;
      color: var(--main);
      cursor: pointer;
      transition: color 0.35s; }
      .catalog-menu__subcategories-show-all::before {
        content: "Показать все";
        border-bottom: 1px dashed var(--main);
        transition: border-color 0.35s; }
      .catalog-menu__subcategories-show-all:hover {
        color: var(--main-darker); }
        .catalog-menu__subcategories-show-all:hover::before {
          border-color: var(--main-darker); }
    .catalog-menu__subcategories.show--all .catalog-menu__subcategories-show-all::before {
      content: "Скрыть"; }
    .catalog-menu__subcategories.show--all .catalog-menu__subcategories-link:nth-child(n+4) {
      display: block; }
  .catalog-menu:hover {
    opacity: 1;
    pointer-events: all; }

.mobile-menu {
  width: 100%;
  position: fixed;
  top: 60px;
  bottom: 0;
  z-index: 5;
  pointer-events: none; }
  .mobile-menu__wrap {
    width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 5;
    left: 100%;
    background: #ffffff;
    transition: transform 0.35s; }
  .mobile-menu__link {
    display: block;
    font-size: calc(0.00125 * 100vw + 13.6px);
    line-height: 1.15;
    color: #333; }
    .mobile-menu__link:not(:last-child) {
      margin-bottom: calc(0.00313 * 100vw + 19px); }
  .mobile-menu__catalog {
    list-style: none; }
    .mobile-menu__catalog .menu-item > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      font-size: 15px;
      line-height: 20px;
      border-bottom: 1px solid #EAEAEA; }
  .mobile-menu__sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background-color: #ffffff;
    overflow: auto;
    transition: transform 0.35s; }
    .mobile-menu__sub-menu.opened {
      transform: translateX(-100%); }
    .mobile-menu__sub-menu .menu-item > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      font-size: 15px;
      line-height: 20px;
      border-bottom: 1px solid #EAEAEA; }
  .mobile-menu__back > a {
    padding: 12px 20px !important;
    color: #ffffff;
    background: var(--main);
    justify-content: flex-start !important; }
  .mobile-menu__back svg {
    margin-right: 16px;
    fill: #fff; }
  .mobile-menu__catalog-link {
    display: block;
    padding: 12px 20px;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff !important;
    background: var(--main); }
  .mobile-menu__search {
    position: relative; }
    .mobile-menu__search-input {
      height: 54px;
      padding: 0 20px;
      background: #F8F8F8; }
    .mobile-menu__search .form-label {
      left: 20px; }
  .mobile-menu__city {
    padding: 10px 20px; }
    .mobile-menu__city-value {
      font-size: 14px;
      line-height: 22px; }
  .mobile-menu__actions {
    border-bottom: 1px solid #EAEAEA; }
    .mobile-menu__actions .header-action {
      height: 52px; }
      .mobile-menu__actions .header-action:not(:last-child) {
        border-right: 1px solid #EAEAEA; }
  .mobile-menu__menu {
    padding: 15px 0;
    background: #F8F8F8;
    list-style: none; }
    .mobile-menu__menu > .menu-item > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      color: #929292;
      font-size: 14px;
      line-height: 22px; }
      .mobile-menu__menu > .menu-item > a .menu-item__arrow {
        fill: #929292; }
  .mobile-menu__contact {
    padding: 16px 34px;
    margin: 0 !important; }
    .mobile-menu__contact:not(:last-child) {
      border-bottom: 1px solid #EAEAEA; }
  .mobile-menu.opened {
    pointer-events: all;
    overflow-x: hidden; }
    .mobile-menu.opened .mobile-menu__wrap {
      transform: translateX(-100%); }
  .mobile-menu .opened--sub-menu {
    height: 100%;
    overflow: hidden; }

.menu-item__arrow {
  width: 10px;
  height: 6px;
  flex: 0 0 auto;
  margin-left: 10px;
  transform: rotate(-90deg); }
  .menu-item__arrow--to-left {
    margin-left: 0;
    transform: rotate(90deg); }

@media (min-width: 1200px) {
  .mobile-menu {
    display: none; } }

.contact-item__value {
  font-size: 14px;
  line-height: 22px;
  color: var(--contact-item-color); }
  .contact-item__value--bold {
    font-weight: 700; }
  .contact-item__value--bigger {
    font-size: 15px; }
  .contact-item__value--alt-color {
    color: var(--contact-item-alt-color); }
  .contact-item__value--underline {
    text-decoration: underline; }

.contact-item__icon {
  fill: var(--contact-item-icon-color); }
  .contact-item__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 18px;
    height: 22px;
    margin-right: 10px; }

.soc-icon {
  display: block;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; }
  .soc-icon__icon {
    fill: #ffffff;
    width: 100%;
    height: 100%; }
  .soc-icon--gray {
    background: #4F4F4F; }
  .soc-icon--main-lighter {
    background: var(--main-lighter); }
    .soc-icon--main-lighter .soc-icon__icon {
      fill: var(--main); }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap; }

.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column; }

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform; }

.swiper-slide-invisible-blank {
  visibility: hidden; }

.main-slider {
  position: relative;
  border-radius: 4px; }
  .main-slider_banner {
    max-width: 100%; }
    @media (max-width: 1199px) {
      .main-slider_banner .container {
        padding: 0 !important; } }
    @media (min-width: 1200px) {
      .main-slider_banner .main-slider__wrapper-text {
        padding: 0 100px; } }
    .main-slider_banner .main-slider__wrapper-arrow {
      display: flex;
      justify-content: space-between;
      position: absolute;
      transform: translateY(-50%) translateX(-50%);
      left: 50%;
      top: 50%;
      z-index: 10; }
    @media (min-width: 1200px) {
      .main-slider_banner .main-slider__controls {
        margin-top: 25px; } }
  .main-slider__container {
    background: #B6B6B6;
    border-radius: 4px; }
  .main-slider__slide {
    height: 500px;
    padding: 0 88px; }
  .main-slider__title {
    margin-bottom: 15px;
    font-size: 42px;
    line-height: 54px;
    font-weight: 700; }
    @media (max-width: 1919px) {
      .main-slider__title {
        margin-bottom: calc(0.00187 * 100vw + 11.4px);
        font-size: calc(0.00937 * 100vw + 24px);
        line-height: calc(0.01312 * 100vw + 28.8px); } }
  .main-slider__text {
    font-size: 16px;
    line-height: 24px; }
    @media (max-width: 1919px) {
      .main-slider__text {
        font-size: calc(0.00187 * 100vw + 12.4px);
        line-height: calc(0.00313 * 100vw + 18px); } }
  .main-slider__title, .main-slider__text {
    max-width: 470px; }
  .main-slider__link {
    margin-top: 28px; }
  .main-slider__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto; }
    .main-slider__arrow--prev {
      left: 0; }
    .main-slider__arrow--next {
      right: 0; }
    .main-slider__arrow--static {
      position: static;
      margin: 0; }
  .main-slider__pagination {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 1; }
    .main-slider__pagination--static {
      position: static; }
  .main-slider__controls--offset {
    margin-top: 9px; }
  .main-slider--white {
    color: #ffffff; }

.slider-arrow {
  width: 30px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: box-shadow 0.35s, background-color 0.35s;
  cursor: pointer; }
  .slider-arrow svg {
    width: 12px;
    height: 8px;
    transform: rotate(90deg);
    fill: #333; }
  .slider-arrow--next {
    order: 1; }
    .slider-arrow--next svg {
      transform: rotate(-90deg); }
  .slider-arrow--small {
    height: 42px; }
  .slider-arrow:hover {
    background: var(--main);
    box-shadow: none; }
    .slider-arrow:hover svg {
      fill: #fff; }

.slider-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #EAEAEA;
  border-radius: 50%;
  transition: background-color 0.35s; }
  .slider-pagination .swiper-pagination-bullet-active {
    background: var(--main); }

@media (min-width: 1200px) {
  .main-slider--border {
    border: 1px solid #B6B6B6; } }

@media (max-width: 1199px) {
  .main-slider__slide {
    height: calc(0.15625 * 100vw + 200px);
    padding: 0 35px; }
  .main-slider__arrow {
    display: none; }
  .main-slider__pagination {
    position: static; }
  .main-slider__controls {
    margin-top: 18px;
    justify-content: center; } }

@media (max-width: 767px) {
  .main-slider__slide {
    padding: 20px;
    justify-content: flex-start; } }

@media (max-width: 567px) {
  .main-slider__slide {
    height: auto; } }

.index-block {
  margin-bottom: calc(0.02187 * 100vw + 28px); }
  .index-block .block-title {
    font-size: calc(0.01 * 100vw + 16.8px); }
  .index-block h1 {
    font-size: calc(0.01 * 100vw + 16.8px) !important; }

@media (max-width: 575px) {
  .hidden--mobile {
    display: none; } }

.index-products__tabs {
  position: relative; }
  .index-products__tabs-container {
    position: relative; }

.index-products__to-all {
  position: absolute;
  right: 0;
  top: 5px; }

.index-products__tab {
  padding-top: calc(0.00375 * 100vw + 22.8px); }
  .index-products__tab:not(.active) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none; }

.products-slider {
  position: relative; }
  .products-slider__slide {
    display: flex;
    height: auto;
    padding: 0 0 60px; }
  .products-slider__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto; }
    .products-slider__arrow--prev {
      left: -15px; }
    .products-slider__arrow--next {
      right: -15px; }
  .products-slider__container {
    height: fit-content; }
  .products-slider__pagination {
    margin-top: -20px;
    position: relative;
    z-index: 1; }

@media (max-width: 1199px) {
  .index-products__tabs-wrap {
    margin: 0 -15px;
    padding: 0 15px; }
  .index-products__tabs {
    justify-content: space-between; }
  .index-products__to-all {
    display: none; }
  .index-products__btn {
    margin: 0 !important; }
  .products-slider {
    margin: 0 -15px;
    padding: 0 25px 0 15px;
    overflow: hidden; }
    .products-slider__slide {
      padding-bottom: 0; }
      .products-slider__slide > .product-card {
        width: 100%; }
    .products-slider__pagination {
      margin: 18px 0 0; }
    .products-slider__container {
      overflow: visible; }
    .products-slider__arrow {
      display: none; } }

.seo-block {
  align-items: flex-start; }
  .seo-block__content, .seo-block__image {
    max-height: calc(0.0875 * 100vw + 222px);
    height: calc(0.0875 * 100vw + 222px); }
  .seo-block__content {
    flex: 0 0 50%;
    max-width: 50%; }
  .seo-block__image {
    width: 100%;
    height: 100%;
    max-width: 47%;
    overflow: hidden; }
    .seo-block__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

@media (min-width: 992px) {
  .seo-block__img--content {
    display: none !important; }
  .seo-block__content-text {
    padding-right: 36px; } }

@media (max-width: 991px) {
  .seo-block__img {
    display: none; }
  .seo-block__content {
    flex: 0 1 100%; }
  .seo-block__content-text {
    padding-right: 15px; } }

@media (max-width: 567px) {
  .content-seo {
    flex-direction: column;
    align-items: center; }
  .seo-block__content,
  .seo-block__image {
    width: 100%;
    max-width: unset;
    margin: 10px 0; } }

.product-card {
  display: flex;
  position: relative;
  height: -webkit-fit-content;
  z-index: 1;
  background: #ffffff;
  transition: box-shadow 0.35s, border-color 0.35s; }
  .product-card_column {
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: 1px solid #EAEAEA;
    border-radius: 4px; }
    @media (min-width: 1200px) {
      .product-card_column .product-card__sidebar {
        display: block; }
      .product-card_column:hover {
        color: #333;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
        z-index: 2; }
        .product-card_column:hover .product-card__quick-view {
          opacity: 1; }
        .product-card_column:hover .product-card__bottom {
          transform: translateY(100%);
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05); }
        .product-card_column:hover .product-card__link {
          border: 1px solid var(--main); }
        .product-card_column:hover .product-card__props {
          visibility: visible;
          opacity: 1; } }
    .product-card_column .product-card__bottom {
      position: absolute;
      width: calc(100% + 2px);
      border: 1px solid #eaeaea;
      border-top: none;
      bottom: 0;
      left: -1;
      z-index: -1; }
      @media (max-width: 1199px) {
        .product-card_column .product-card__bottom {
          margin-top: auto;
          position: relative;
          border-bottom: none; } }
    @media (max-width: 575px) {
      .product-card_column .product-card__wrapper {
        flex-direction: row;
        padding-left: 10px;
        padding-right: 20px;
        padding-top: 17px;
        padding-bottom: 18px; } }
    .product-card_column .product-card__img {
      width: 100%;
      margin: auto; }
      @media (min-width: 576px) {
        .product-card_column .product-card__img-wrap-2 {
          padding: 25px 0; } }
      @media (max-width: 575px) {
        .product-card_column .product-card__img-wrap-2 {
          flex-shrink: 0;
          width: 29vw;
          height: 19vw; } }
      @media (min-width: 576px) {
        .product-card_column .product-card__img {
          height: 152px; } }

@media (max-width: 1199px) and (min-width: 576px) {
  .product-card_column .product-card__img {
    max-height: 160px; } }
      .product-card_column .product-card__img_cup {
        background-color: #F1F7FA; }
    .product-card_column .product-card__info {
      display: flex;
      flex-direction: column;
      border-radius: 0 0 4px 4px;
      flex-grow: 1; }
      @media (min-width: 576px) {
        .product-card_column .product-card__info {
          padding: 30px 27px 18px; }
          .product-card_column .product-card__info .product-card__price-block {
            margin-bottom: 18px; } }
      @media (max-width: 575px) {
        .product-card_column .product-card__info {
          margin-left: 10px; }
          .product-card_column .product-card__info .product-card__price-block {
            order: 1; }
          .product-card_column .product-card__info .product-card__name {
            margin-bottom: 10px; } }
    .product-card_column .product-card__name {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500; }
      @media (max-width: 1919px) {
        .product-card_column .product-card__name {
          font-size: calc(0.00187 * 100vw + 12.4px);
          line-height: calc(0.00187 * 100vw + 20.4px); } }
    .product-card_column .product-card__link {
      border-radius: 0 4px 0 4px;
      border: 1px solid #eaeaea; }
      .product-card_column .product-card__link-wrap {
        flex: 0 0 63.6%;
        transform: translate(-1px, 1px); }
      @media (max-width: 1199px) {
        .product-card_column .product-card__link {
          border: 1px solid var(--main); } }
    @media (max-width: 1199px) {
      .product-card_column .product-card__quick-view {
        display: none; } }
    @media (max-width: 575px) {
      .product-card_column .product-card__description {
        display: none; } }
    .product-card_column .product-card__delete {
      display: flex;
      position: absolute;
      width: 22px;
      height: 22px;
      top: 10px;
      right: 10px;
      z-index: 1; }
      .product-card_column .product-card__delete:hover svg {
        fill: var(--main); }
      .product-card_column .product-card__delete a {
        margin: auto; }
      .product-card_column .product-card__delete svg {
        fill: #B6B6B6; }
    .product-card_column .product-card__props {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1; }
      .product-card_column .product-card__props::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: var(--main);
        opacity: 0.93;
        z-index: -1; }
      .product-card_column .product-card__props-wrapper {
        width: 100%;
        height: 100%;
        padding: 25px; }
      .product-card_column .product-card__props-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between; }
        .product-card_column .product-card__props-item:not(:last-child) {
          margin-bottom: 2px; }
        .product-card_column .product-card__props-item span {
          display: block; }
      .product-card_column .product-card__props-name {
        color: #DAF4F3;
        font-size: 12px;
        line-height: 20px;
        padding-right: 5px; }
      .product-card_column .product-card__props-value {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        line-height: 20px; }
  .product-card_row {
    flex-direction: row;
    border-top: 1px solid #EAEAEA;
    height: 100px; }
    .product-card_row-bet {
      border-left: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea; }
      .product-card_row-bet:first-child {
        border-top-color: #eaeaea; }
      .product-card_row-bet.unavailable {
        background-color: rgba(211, 211, 211, 0.5); }
        .product-card_row-bet.unavailable .product-card__img-wrap-2 {
          opacity: .5; }
        .product-card_row-bet.unavailable .product-card__info {
          background-color: transparent;
          opacity: .5; }
        .product-card_row-bet.unavailable .product-card__bottom {
          background-color: transparent; }
        .product-card_row-bet.unavailable .product-card__link-wrap {
          pointer-events: none;
          opacity: 0;
          visibility: hidden; }
      .product-card_row-bet:hover {
        border-color: transparent; }
        .product-card_row-bet:hover + * {
          border-top-color: transparent; }
      .product-card_row-bet .product-card__info {
        margin-left: 20px;
        max-width: inherit;
        flex-grow: 1;
        justify-content: flex-start; }
      .product-card_row-bet .product-card__price-block {
        width: fit-content; }
      .product-card_row-bet .product-card__name {
        display: flex;
        min-width: 480px; }
      .product-card_row-bet .product-card__bottom {
        width: 300px;
        flex-shrink: 0; }
      .product-card_row-bet .product-card__link {
        width: 156px; }
    .product-card_row .product-card__wrapper {
      display: contents; }
    .product-card_row .product-card__img {
      object-fit: contain;
      width: 120px;
      padding: 10px;
      margin-right: 20px; }
      .product-card_row .product-card__img_cup .product-card__img {
        background-color: #F1F7FA; }
    .product-card_row .product-card__quick-view {
      display: none; }
    .product-card_row .product-card__info {
      display: flex;
      width: 100%;
      max-width: 475px;
      align-items: center;
      justify-content: space-between; }
    .product-card_row .product-card__description {
      display: none; }
    .product-card_row .product-card__price {
      font-size: 18px; }
    .product-card_row .product-card__price-block {
      flex-shrink: 0;
      order: 2;
      width: 160px;
      max-width: 200px; }
    .product-card_row .product-card__name {
      max-width: 260px;
      font-size: 15px;
      font-weight: normal;
      line-height: 20px; }
    .product-card_row .product-card__link {
      width: 112px; }
    .product-card_row .product-card__bottom {
      width: 216px;
      margin: auto;
      margin-right: 15px; }
    .product-card_row .product-card__sidebar {
      display: none; }
    .product-card_row:first-child {
      border-top-color: transparent; }
    .product-card_row:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
      z-index: 2; }
      .product-card_row:hover + div {
        border-top-color: transparent; }
  .product-card_variation .product-card__quick-view {
    display: none; }
  .product-card_variation:hover {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
    .product-card_variation:hover .product-card__sidebar {
      opacity: 1;
      visibility: visible;
      transform: translateX(calc(-100% - 1px)); }
    .product-card_variation:hover .product-card__link {
      border-bottom-left-radius: 0px; }
  .product-card_cart {
    display: table-row; }
    .product-card_cart.favorite .svg-favorite use:first-child {
      display: none; }
    .product-card_cart.favorite .svg-favorite use:last-child {
      display: block;
      fill: var(--main); }
    .product-card_cart.removed .cart-list__column:not(:last-child):not(:first-child) {
      opacity: 0.6; }
    .product-card_cart.removed .product-card__action_close {
      display: none; }
    .product-card_cart.removed .product-card__action_repeat {
      display: block; }
      .product-card_cart.removed .product-card__action_repeat svg {
        fill: var(--main); }
    .product-card_cart.unavailable .cart-list__column:not(:first-child) {
      background-color: rgba(211, 211, 211, 0.5); }
    .product-card_cart.unavailable .cart-list__column:not(:last-child):not(:first-child) > * {
      opacity: 0.5; }
    .product-card_cart.unavailable .product-card__info {
      background: transparent; }
    .product-card_cart.unavailable .product-card__amount {
      display: none; }
    .product-card_cart.unavailable .article-counter {
      display: none; }
    .product-card_cart.unavailable .product-card__price {
      display: none; }
      .product-card_cart.unavailable .product-card__price_visible {
        display: block; }
    .product-card_cart.unavailable .product-card__bottom {
      background-color: transparent; }
    .product-card_cart.unavailable .filter-item {
      background-color: #fff;
      opacity: 1 !important; }
    .product-card_cart .product-card__bagdes {
      left: 4px;
      top: 4px;
      font-size: 11px; }
    .product-card_cart .product-card__action {
      flex-grow: 0; }
      .product-card_cart .product-card__action-svg {
        width: 24px;
        height: 24px;
        fill: #B6B6B6; }
      .product-card_cart .product-card__action_close svg {
        width: 16px;
        height: 18px; }
    .product-card_cart .product-card__bottom {
      justify-content: space-between; }
    .product-card_cart .product-card__amount {
      white-space: nowrap;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px; }
    .product-card_cart .product-card__old-price {
      white-space: nowrap; }
    .product-card_cart .product-card__img {
      object-fit: contain; }
  .product-card_action--more .product-card__link::before {
    content: 'Подробнее'; }
  .product-card_action--cart .product-card__link::before {
    content: 'В корзину'; }
  .product-card_delete .product-card__delete {
    display: block; }
  .product-card__sidebar {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-right: 0;
    box-shadow: -3px 12px 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px 0px 0px 4px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    height: calc(100% + 44px);
    width: 100px;
    opacity: 0;
    visibility: hidden;
    transition: all ease 400ms, opacity ease 200ms;
    top: -1px;
    left: 0; }
    .product-card__sidebar-wrap {
      margin-right: 12px; }
  .product-card__variation {
    display: block;
    object-fit: cover;
    object-position: center;
    margin: auto;
    width: 48px;
    height: 48px;
    max-width: 100%;
    max-height: 100%; }
    .product-card__variation-wrap {
      display: flex;
      border: 1px solid #EAEAEA;
      border-radius: 4px;
      width: 60px;
      height: 62px; }
      .product-card__variation-wrap:not(:last-child) {
        margin-bottom: 15px; }
  .product-card__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .product-card__img {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    max-width: 230px;
    max-height: 152px; }
    .product-card__img-wrap {
      display: flex;
      height: inherit;
      height: 70px; }
      .product-card__img-wrap .product-card__img {
        width: 100%; }
    .product-card__img-wrap-2 {
      display: flex;
      position: relative; }
    .product-card__img_cup .product-card__img {
      display: flex; }
      .product-card__img_cup .product-card__img .svg-no-image {
        fill: grey;
        width: 50%;
        height: 50%;
        margin: auto; }
    .product-card__img_cup svg {
      margin: auto; }
  .product-card__price {
    font-size: 22px;
    line-height: 1;
    font-weight: 700; }
    @media (max-width: 1919px) {
      .product-card__price {
        font-size: calc(0.00125 * 100vw + 19.6px); } }
    .product-card__price-from {
      font-size: 17px; }
    .product-card__price--no-price {
      font-size: 20px; }
      @media (max-width: 1919px) {
        .product-card__price--no-price {
          font-size: calc(0.0025 * 100vw + 15.2px); } }
    .product-card__price--cart, .product-card__price--currency {
      white-space: nowrap;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px; }
    .product-card__price_visible {
      display: none; }
  @media (max-width: 575px) {
    .product-card__price-block {
      margin-top: auto; } }
  .product-card__info {
    background: #ffffff; }
  .product-card__quick-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 202px;
    opacity: 0;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    transition: opacity 0.35s; }
    .product-card__quick-view::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--main);
      opacity: 0.3; }
    .product-card__quick-view__btn {
      position: relative;
      padding: 0 24px; }
  .product-card__old-price {
    font-size: 15px;
    line-height: 20px;
    color: #cccccc;
    text-decoration: line-through; }
  .product-card__discount {
    margin-right: 13px;
    font-size: 12px; }
  .product-card__price-row {
    margin-bottom: 3px; }
  .product-card__bottom {
    background: #ffffff;
    border-radius: 0 0 4px 4px;
    transition: transform 0.35s; }
  .product-card__link:before {
    content: 'Подробнее'; }
  .product-card__action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer; }
    .product-card__action_repeat {
      display: none; }
    .product-card__action:hover .header-action__icon {
      fill: var(--main); }
  .product-card__description {
    margin-top: 6px;
    font-size: 15px;
    line-height: 20px;
    color: #929292; }
  .product-card__bagdes {
    left: 13px;
    top: 13px;
    font-size: 12px; }
  .product-card__delete {
    display: none; }
  .product-card__warning {
    right: 0px;
    transform: translate(50%, -50%); }
  .product-card__props {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility ease 400ms, opacity ease 400ms; }

.product-bagdes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute; }

.product-bagde {
  display: inline-block;
  padding: 4px 5px;
  color: #ffffff;
  line-height: 1;
  border-radius: 2px; }
  .product-bagde--discount {
    background: #ED6262; }
  .product-bagde--new {
    background: var(--main); }
  .product-bagde--hit {
    color: #333;
    background: var(--additional); }
  .product-bagde--bottom-offset:not(:last-child) {
    margin-bottom: 4px; }

.news-block {
  display: block;
  position: relative;
  border-radius: 4px;
  transition: box-shadow 0.35s;
  overflow: hidden; }
  .news-block__img {
    width: 100%;
    padding-bottom: 62%;
    border-radius: 4px;
    overflow: hidden;
    transition: border-radius 0.35s; }
  .news-block__info {
    display: block;
    padding: 17px 17px 22px; }
  .news-block__date {
    display: block;
    font-size: 12px;
    color: #929292; }
    .news-block__date--bottom-offset {
      margin-bottom: 12px; }
    .news-block__date--top-offset {
      margin-top: 18px; }
  .news-block__title {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    transition: .2s; }
  .news-block__text {
    display: block;
    margin-top: 25px;
    font-size: 15px;
    line-height: 20px;
    color: #929292; }
  .news-block__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 62%;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.35s; }
    .news-block__cover::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: var(--main);
      opacity: 0.3; }
    .news-block__cover-btn {
      width: 125px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      margin: auto; }
  .news-block__cup {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #F1F7FA; }
    .news-block__cup svg {
      margin: auto; }
  .news-block:hover {
    color: #333;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05); }
    .news-block:hover .news-block__img {
      border-radius: 4px 4px 0 0; }
    .news-block:hover .news-block__cover {
      opacity: 1; }

.news-grid__grid {
  height: fit-content; }

.news-grid__pagination {
  margin: auto;
  margin-top: 20px; }

.news-all {
  padding: calc(0.00937 * 100vw + 12px) 0 0; }
  .news-all__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: calc(0.00937 * 100vw + 37px);
    background: var(--main);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF !important;
    margin: auto;
    transition: .2s; }
    .news-all__link:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

@media (min-width: 1200px) {
  .news-grid {
    overflow: visible; }
    .news-grid__wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;
      box-sizing: border-box; }
      .news-grid__wrap--wide-columns {
        grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 1199px) {
  .news-grid {
    margin: 0 -15px;
    padding: 0 15px; }
    .news-grid__slide {
      margin-bottom: 18px; }
  .news-block {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05); } }

.partners-slider {
  position: relative; }
  .partners-slider__img {
    max-width: 175px;
    user-select: none;
    object-fit: scale-down; }
    .partners-slider__img--gray {
      transition: filter 0.35s; }
      .partners-slider__img--gray:not(:hover) {
        filter: saturate(0%); }
  .partners-slider__controls--top-offset {
    margin-top: 24px; }
  .partners-slider__arrow {
    position: relative;
    z-index: 3; }
  .partners-slider__show-all {
    margin-top: 0px; }
    @media (min-width: 992px) {
      .partners-slider__show-all {
        display: none; } }
  .partners-slider__slide {
    height: -webkit-fit-content;
    transition: all 0.35s;
    opacity: 1;
    visibility: visible; }
    .partners-slider__slide_hidden {
      height: 0;
      transition: all 0.35s;
      opacity: 0;
      visibility: hidden; }

@media (min-width: 1200px) {
  .partners-slider-2__slide {
    padding: 0 73px; }
  .partners-slider-2__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 3; }
    .partners-slider-2__arrow.partners-slider__arrow--prev {
      left: 0; }
    .partners-slider-2__arrow.partners-slider__arrow--next {
      right: 0; }
  .partners-slider-2::before, .partners-slider-2::after {
    content: '';
    width: 73px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: #ffffff; }
  .partners-slider-2::before {
    left: 0; }
  .partners-slider-2::after {
    right: 0; }
  .partners-slider-3__arrow {
    margin-left: 19px; }
  .partners-slider-3__controls {
    margin-bottom: 30px; } }

@media (max-width: 1199px) {
  .partners-slider__controls {
    display: none; }
  .partners-slider__slide {
    display: grid;
    grid-template-columns: repeat(4, auto);
    row-gap: 30px;
    margin-bottom: 30px; }
    .partners-slider__slide_hidden {
      margin-bottom: 0px; }
  .partners-slider__img {
    max-width: 32vw; }
  .partners-slider .swiper-wrapper {
    flex-direction: column; } }

@media (max-width: 991px) {
  .partners-slider__slide {
    grid-template-columns: repeat(3, auto); }
  .partners-slider__img {
    max-width: 31.5vw; } }

@media (max-width: 575px) {
  .partners-slider__slide {
    grid-template-columns: repeat(2, auto); }
  .partners-slider__img {
    max-width: 39vw; } }

.advantages {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 32px;
  row-gap: 32px; }

.advantage__title {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: calc(0.00187 * 100vw + 14.4px);
  line-height: calc(0.00375 * 100vw + 18.8px); }

.advantage__text {
  color: #929292;
  font-size: calc(0.00125 * 100vw + 12.6px);
  line-height: calc(0.00313 * 100vw + 16px); }

.advantage__icon {
  display: block;
  width: 55%;
  height: 55%;
  object-fit: scale-down;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }
  .advantage__icon-decor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
    .advantage__icon-decor--part1 {
      width: 100%;
      height: 100%;
      fill: var(--main); }
    .advantage__icon-decor--part2 {
      width: 76%;
      height: 76%;
      fill: var(--main-lighter); }

.advantage__icon-wrap {
  width: 100px;
  height: 100px;
  margin-bottom: calc(0.00438 * 100vw + 6.6px);
  position: relative; }

@media (max-width: 991px) {
  .advantage {
    display: flex; }
    .advantage__icon-wrap {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      flex: 0 0 auto; }
  .advantages {
    row-gap: 25px;
    grid-template-columns: 1fr 1fr; } }

@media (max-width: 575px) {
  .advantage {
    display: flex; }
    .advantage__icon-wrap {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      flex: 0 0 auto; }
  .advantages {
    grid-template-columns: 1fr; } }

.block-title {
  font-weight: 700;
  font-size: calc(0.01063 * 100vw + 24.6px);
  line-height: calc(0.0125 * 100vw + 28px); }
  .block-title_margin {
    margin-bottom: calc(0.00625 * 100vw + 28px); }
    .block-title_margin--page {
      margin-bottom: calc(0.00313 * 100vw + 24px); }
      .block-title_margin--page-top {
        margin-top: 40px; }

.category-block {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  overflow: hidden; }
  .category-block__img {
    object-fit: scale-down; }

.category-block-1 {
  min-height: 213px;
  position: relative; }
  .category-block-1__title {
    position: relative;
    padding: 0 calc(0.00187 * 100vw + 6.4px) calc(0.00438 * 100vw + 6.6px);
    font-size: calc(0.00313 * 100vw + 9px);
    line-height: calc(0.00562 * 100vw + 11.2px);
    font-weight: 700;
    text-align: center; }
  .category-block-1__img {
    width: 138px;
    height: 138px; }
  .category-block-1::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--main);
    opacity: 0;
    transition: opacity 0.35s; }
  .category-block-1:hover {
    color: #ffffff; }
    .category-block-1:hover::before {
      opacity: 0.8; }

.category-block-2 {
  min-height: 115px;
  padding: 23px 27px;
  position: relative;
  transition: border-color 0.35s, box-shadow 0.35s; }
  .category-block-2__title {
    max-width: 60%;
    font-size: 15px;
    line-height: 20px; }
  .category-block-2__img {
    width: 105px;
    height: 105px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 13px;
    margin: auto; }
  .category-block-2:hover {
    border-color: transparent;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05); }

.category-block-3 {
  display: flex;
  flex-direction: column;
  min-height: 320px; }
  .category-block-3__header {
    position: relative;
    height: 130px;
    padding: 30px; }
  .category-block-3__rect {
    transition: all ease-in-out 0.35s;
    position: absolute;
    z-index: -1;
    width: 30px;
    height: 12px;
    left: 0;
    top: 0;
    background-color: var(--main); }
  .category-block-3__title {
    transition: all ease 0.35s;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px; }
  .category-block-3__img {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%; }
    .category-block-3__img-wrap {
      margin-left: auto;
      max-width: 210px;
      max-height: 180px; }
  .category-block-3__wrapper-image {
    display: flex;
    flex-grow: 1;
    padding: 10px; }
  .category-block-3:hover {
    border-color: transparent;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05); }
    .category-block-3:hover .category-block-3__title {
      color: #fff; }
    .category-block-3:hover .category-block-3__rect {
      width: 100%;
      height: 100%; }

@media (max-width: 991px) {
  .category-block-1 {
    min-height: 113px; }
    .category-block-1__img {
      width: 75px;
      height: 60px; }
  .category-block-2 {
    min-height: 94px;
    display: flex;
    align-items: center; }
    .category-block-2__img {
      width: 73px;
      height: 73px; } }

.article__stand {
  display: grid;
  grid-template-columns: 50% 1fr;
  column-gap: 32px; }
  @media (max-width: 1199px) {
    .article__stand {
      grid-template-columns: 100%;
      row-gap: 30px; } }
  .article__stand-column:first-child {
    max-width: 640px; }
    @media (max-width: 1199px) {
      .article__stand-column:first-child {
        margin: 0 auto; } }

.article__content {
  margin-top: 50px; }
  @media (max-width: 1919px) {
    .article__content {
      margin-top: calc(0.01562 * 100vw + 20px); } }

.article__more {
  margin-top: 60px;
  display: grid;
  grid-template-areas: "description receive";
  grid-template-columns: 840px 1fr;
  column-gap: 60px; }
  @media (max-width: 1919px) {
    .article__more {
      margin-top: calc(0.02187 * 100vw + 18px); } }
  @media (max-width: 1199px) {
    .article__more {
      grid-template-areas: "receive" "description";
      grid-template-columns: 100%;
      row-gap: 30px; } }
  .article__more-description {
    grid-area: description; }
  .article__more-receive {
    grid-area: receive;
    max-width: 420px;
    width: 100%; }

.article__like {
  margin-top: 70px; }
  @media (max-width: 1919px) {
    .article__like {
      margin-top: calc(0.025 * 100vw + 22px); } }

.article-properties__header {
  padding-bottom: 15px;
  border-bottom: 1px solid #EAEAEA; }
  .article-properties__header_mod {
    display: flex; }
    .article-properties__header_mod .article-control {
      margin-top: 0px; }
    .article-properties__header_mod .article-manager {
      width: 240px; }
    .article-properties__header_mod .article-counter {
      width: 100px; }

.article-properties__wrapper-popup {
  display: flex;
  flex-direction: column; }
  .article-properties__wrapper-popup > *:first-child {
    margin-bottom: 10px; }

.article-properties__wrapper-feature {
  padding-right: 15px;
  max-height: calc(100% - 110px); }

.article-properties__feature {
  margin-top: 20px; }

.article-properties__body {
  border-bottom: 1px solid #eaeaea;
  padding-top: 15px;
  padding-bottom: 15px; }
  .article-properties__body-2 {
    width: fit-content;
    padding-top: 25px; }
    @media (max-width: 575px) {
      .article-properties__body-2 {
        width: 100%; } }
    .article-properties__body-2 .article-filter:not(:last-child) {
      margin-bottom: 45px; }
    .article-properties__body-2 .article-plus {
      margin-bottom: 20px; }
    .article-properties__body-2 > .article-result {
      margin-bottom: 20px; }

.article-properties__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px; }
  @media (max-width: 767px) {
    .article-properties__footer {
      flex-direction: column;
      align-items: flex-start; } }

.article-properties__ribbon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: calc(0.00937 * 100vw + 12px); }
  @media (max-width: 575px) {
    .article-properties__ribbon {
      flex-direction: column;
      align-items: flex-start; } }

.article-properties__title {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #333333; }
  @media (max-width: 1919px) {
    .article-properties__title {
      font-size: calc(0.01 * 100vw + 16.8px);
      line-height: calc(0.01125 * 100vw + 22.4px); } }

.article-properties .article-manager {
  margin-left: 25px; }

.article-properties .simplebar-vertical {
  margin: 20px 0; }

.acticle-observe {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .acticle-observe__svg {
    width: 16px;
    height: 16px;
    margin-right: 10px; }
  .acticle-observe__title {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    transition: all ease 400ms; }
  .acticle-observe:hover .acticle-observe__title {
    color: var(--main); }
  .acticle-observe:hover svg {
    fill: var(--main); }
  .acticle-observe:active .acticle-observe__title {
    color: var(--main-darker); }
  .acticle-observe:active svg {
    fill: var(--main-darker); }
  .acticle-observe_disable {
    pointer-events: none;
    opacity: 0.8; }
    .acticle-observe_disable svg {
      fill: #B6B6B6; }
    .acticle-observe_disable .acticle-observe__title {
      color: #B6B6B6; }

.article-control {
  display: flex;
  align-items: center;
  margin-top: 30px; }
  @media (max-width: 575px) {
    .article-control {
      flex-wrap: wrap;
      margin-top: 15px; } }
  @media (min-width: 576px) {
    .article-control__item:first-child {
      margin-right: 45px; } }
  @media (max-width: 575px) {
    .article-control__item:first-child {
      width: 100%;
      margin-bottom: 10px; } }
  .article-control__item:not(:first-child):not(:last-child) {
    margin-right: 30px; }

.article-warehouse {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .article-warehouse__svg {
    width: 16px;
    height: 16px;
    fill: var(--main);
    margin-right: 6px; }
  .article-warehouse__title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px; }

.article-filter__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #929292;
  margin-bottom: 18px; }

.article-filter__list_type {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8.5px; }

.article-filter__list_color {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 420px;
  margin-left: -5.5px; }

.article-filter__item-type {
  margin: 0 8.5px;
  margin-bottom: 15px; }

.article-filter__item-color {
  margin: 0 5.5px;
  margin-bottom: 11px; }

.article-counter {
  display: flex;
  position: relative;
  width: 100px;
  height: 42px;
  border: 1px solid #D5D5D5;
  border-radius: 4px;
  background: #FFFFFF; }
  .article-counter__value {
    border: none;
    width: 100%;
    text-align: center; }
  .article-counter__compute {
    cursor: pointer; }
    .article-counter__compute_minus {
      padding-left: 13px; }
    .article-counter__compute_plus {
      padding-right: 13px; }
    .article-counter__compute_disable {
      cursor: auto; }
      .article-counter__compute_disable svg {
        fill: #d5d5d5; }
  .article-counter__svg {
    flex-shrink: 0;
    width: 13px;
    height: 100%; }

.article-manager {
  display: grid;
  max-width: 100%;
  width: 350px;
  grid-template-areas: "menu cart" "buy buy";
  grid-template-columns: 100px 1fr;
  grid-template-rows: repeat(2, 42px);
  grid-column-gap: 16px;
  grid-row-gap: 10px; }
  .article-manager__counter {
    grid-area: menu; }
  .article-manager__cart {
    grid-area: cart; }
  .article-manager__buy {
    grid-area: buy; }
  .article-manager .btn {
    display: flex;
    align-items: center;
    justify-content: center; }

.article-block-price {
  height: fit-content; }
  .article-block-price__wrapper {
    display: flex;
    align-items: center; }
    .article-block-price__wrapper > *:first-child {
      margin-right: 15px; }

.article-price_current {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 44px; }

.article-price_old {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: #B6B6B6; }

.article-price_from {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 44px;
  color: var(--main); }
  @media (max-width: 575px) {
    .article-price_from {
      margin-bottom: 5px; } }

.article-price_min {
  display: flex; }
  .article-price_min > *:first-child {
    margin-right: 12px; }
  .article-price_min .article-price__svg {
    width: 21px;
    height: 21px;
    fill: var(--main); }
  .article-price_min .article-price__title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #929292; }
    .article-price_min .article-price__title b {
      font-weight: bold;
      color: var(--main); }
    @media (max-width: 1919px) {
      .article-price_min .article-price__title {
        font-size: calc(0.00125 * 100vw + 12.6px); } }

.article-slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  position: relative; }
  .article-slider__image {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    transition: all ease 300ms;
    border-radius: 4px; }
  .article-slider__view {
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    height: 415px; }
    @media (max-width: 1919px) {
      .article-slider__view {
        height: calc(0.05312 * 100vw + 313px); } }
  .article-slider__nav {
    height: 125px; }
    .article-slider__nav .slick-slide {
      position: relative;
      margin-top: auto;
      width: 120px;
      height: 90px;
      padding: 10px;
      filter: grayscale(1); }
      .article-slider__nav .slick-slide:before {
        opacity: 0;
        visibility: hidden;
        top: -41px;
        content: '';
        position: absolute;
        left: 50%;
        border-style: solid;
        border-width: 0 12.5px 20px 12.5px;
        border-color: transparent transparent var(--main) transparent;
        transform: rotate(180deg) translateX(50%); }
    .article-slider__nav .slick-current {
      padding: 0;
      transform: translateY(-15px);
      border: 1px solid var(--main);
      border-radius: 4px;
      transition: all ease 300ms;
      filter: none; }
      .article-slider__nav .slick-current:before {
        transition: all ease 400ms;
        display: block;
        top: -21px;
        opacity: 1;
        visibility: visible; }
  .article-slider__zoom {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    top: 23px;
    right: 25px;
    width: 23px;
    height: 23px;
    fill: var(--main); }
  .article-slider__bagdes {
    left: 15px;
    top: 15px;
    z-index: 1; }
  .article-slider__item_stand {
    align-items: center;
    justify-content: center; }
    .article-slider__item_stand svg {
      transform: scale(1.67); }
  .article-slider__item_cup {
    background-color: #F1F7FA; }
  .article-slider-arrow {
    position: absolute;
    width: 30px;
    height: 42px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    bottom: 25px;
    transition: all ease 400ms; }
    .article-slider-arrow path {
      transition: all ease 400ms; }
    .article-slider-arrow:hover {
      background-color: var(--main); }
      .article-slider-arrow:hover path {
        fill: #FFFFFF; }
    .article-slider-arrow_next {
      right: 0; }
    .article-slider-arrow_prev {
      left: 0; }
  .article-slider .slick-list {
    padding: 0 !important;
    overflow: hidden; }
  .article-slider .slick-track {
    display: flex;
    height: 100%; }
  .article-slider .slick-slide {
    display: flex;
    max-height: 415px; }

.article-description {
  display: flex; }

.article-feature__tabs {
  display: flex;
  margin-bottom: 25px;
  border-bottom: 1px solid #EAEAEA; }
  @media (max-width: 767px) {
    .article-feature__tabs {
      flex-direction: column; } }

.article-feature-content__ribbon {
  display: flex;
  flex-direction: column; }
  .article-feature-content__ribbon:not(:last-child) {
    margin-bottom: 25px; }
  .article-feature-content__ribbon-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px; }
  .article-feature-content__ribbon .text-line {
    margin-bottom: 12px; }
  .article-feature-content__ribbon > *:last-child {
    margin-bottom: 0px; }

.article-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 60px;
  border: 1px solid transparent;
  border-bottom: none;
  border-radius: 4px 4px 0px 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px; }
  .article-tab_active {
    border: 1px solid #EAEAEA;
    color: var(--main); }
    @media (min-width: 768px) {
      .article-tab_active {
        border-bottom: none; } }
  .article-tab:last-child {
    border-bottom: none; }
  .article-tab_feature {
    width: calc(100%/4); }
    @media (max-width: 767px) {
      .article-tab_feature {
        width: 100%; } }

.article-table {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  width: fit-content; }
  @media (max-width: 575px) {
    .article-table {
      width: 100vw;
      overflow: overlay; } }
  .article-table_mod {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  @media (max-width: 991px) {
    .article-table_mod-2 {
      width: 100vw !important;
      overflow: overlay; } }
  .article-table_mod-2 .article-table__table {
    min-width: 1000px; }
  .article-table_mod-2 .article-table__column {
    border-left: 1px solid #EAEAEA;
    padding-left: 24px; }
    .article-table_mod-2 .article-table__column:first-child {
      border-left: none; }
  .article-table__table {
    min-width: 500px;
    border-spacing: 0; }
    .article-table__table_2 .article-table__column:last-child {
      padding-right: 40px; }
  .article-table__row:not(:last-child) td {
    border-bottom: 1px solid #EAEAEA; }
  .article-table__row_result {
    border: 1px solid #eaeaea;
    border-top: none;
    border-radius: 0px 0px 4px 4px; }
    .article-table__row_result .article-table__column:last-child {
      padding-right: 40px; }
  .article-table__row_header {
    background: #F8F8F8; }
    .article-table__row_header td {
      padding: 12px 0;
      color: #B6B6B6; }
  .article-table__column {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    padding: 20px 0; }
    .article-table__column:not(:last-child) {
      padding-right: 30px; }
    .article-table__column:first-child {
      padding-left: 24px; }
    .article-table__column:last-child {
      padding-right: 18px; }
    .article-table__column_flex {
      display: flex;
      justify-content: space-between; }
    .article-table__column_point.plus {
      color: var(--main); }
    .article-table__column_point.minus {
      color: #ED6262; }
  .article-table__cart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    cursor: pointer;
    width: 60px;
    height: 42px;
    border-radius: 4px;
    color: #fff;
    background-color: var(--main); }
    .article-table__cart-svg {
      width: 24px;
      height: 24px;
      fill: #fff; }
  .article-table__counter {
    width: 90px;
    height: 42px; }
  .article-table__price {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px; }
    .article-table__price-old {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 12px;
      text-decoration-line: line-through;
      color: #B6B6B6; }
      .article-table__price-old + span {
        color: var(--main); }
    .article-table__price-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 15px;
      flex-shrink: 0; }

.article-result {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 575px) {
    .article-result {
      flex-direction: column;
      padding-right: 24px !important; } }
  .article-result :first-child {
    margin-right: 12px; }
  .article-result__manage {
    display: flex; }
    .article-result__manage-btn {
      height: 42px; }
      .article-result__manage-btn_buy {
        width: 137px; }
      .article-result__manage-btn_cart {
        width: 180px;
        color: var(--main) !important;
        border: 1px solid var(--main);
        background-color: #fff; }
  .article-result__price {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 44px;
    display: flex;
    align-items: center;
    color: var(--main); }
    .article-result__price-wrap {
      display: flex;
      align-items: center;
      width: fit-content; }
    .article-result__price-title {
      margin-right: 15px; }

.article-plus {
  padding: 17px 30px 30px 23px;
  background-color: #F8F8F8; }
  .article-plus__title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px; }
  .article-plus__list {
    margin-bottom: 0px !important; }
    .article-plus__list-item {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 3px !important; }
  .article-plus__link {
    color: var(--main); }

.article-receive {
  display: flex; }
  .article-receive__item {
    width: 50%; }
    .article-receive__item:first-child {
      margin-right: 50px; }
    .article-receive__item > * {
      margin-bottom: 8px; }
      .article-receive__item > *:nth-last-child(2) {
        margin-bottom: 5px; }
  .article-receive__title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #929292; }
  .article-receive__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px; }
    .article-receive__subtitle_link {
      border-bottom: 1px dashed var(--main);
      color: var(--main); }

.action:hover .action__info {
  opacity: 1;
  color: #fff; }

.action_fix-margin {
  margin-bottom: -20px; }

.action-2 .action__info {
  padding: 20px; }

.action__cup {
  margin: auto;
  width: fit-content; }

.action .bg {
  display: flex;
  background-color: #F1F7FA; }

.action-1 {
  height: 314px;
  flex: 0 0 calc(50% - 32px);
  position: relative;
  color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 32px; }
  .action-1__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 35px 35px 49px;
    opacity: 0;
    transition: opacity 0.35s; }
    .action-1__content::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--main);
      opacity: 0.92; }
  .action-1__date {
    font-size: calc(0.0025 * 100vw + 9.2px);
    line-height: 1; }
  .action-1__title {
    margin-bottom: 10px;
    font-size: calc(0.0025 * 100vw + 15.2px);
    line-height: calc(0.0025 * 100vw + 21.2px);
    font-weight: 700; }
  .action-1__text {
    max-width: 350px;
    width: 100%;
    font-size: calc(0.00187 * 100vw + 12.4px);
    line-height: calc(0.00313 * 100vw + 18px); }
  .action-1__date, .action-1__title, .action-1__text, .action-1__btn {
    display: block;
    position: relative;
    z-index: 1; }
  .action-1__btn {
    display: inline-flex;
    padding: 0 43px; }
  .action-1__bg {
    border-radius: 4px;
    overflow: hidden; }
  .action-1:hover {
    color: #fff; }
    .action-1:hover .action-1__content {
      opacity: 1; }

@media (max-width: 1199px) {
  .action-1 {
    height: auto;
    margin-bottom: 14px; }
    .action-1__bg {
      position: static; }
      .action-1__bg .bg__img {
        height: auto; }
    .action-1__content {
      position: static;
      display: none;
      padding: 27px 23px; }
    .action-1__date {
      margin-bottom: 19px; }
    .action-1__title {
      font-weight: 500; }
    .action-1__text {
      margin-bottom: 20px;
      max-width: unset; }
    .action-1__btn {
      width: 100%;
      padding: 0; }
    .action-1:hover .action-1__bg {
      position: absolute; }
    .action-1:hover .action-1__content {
      display: block; } }

@media (max-width: 575px) {
  .action-1 {
    flex-basis: calc(100% - 32px); } }

.action-2 {
  position: relative;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  overflow: hidden;
  transition: border-color 0.35s, box-shadow 0.35s; }
  .action-2:hover {
    border-color: transparent;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05); }

.actions-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 32px;
  row-gap: 32px;
  align-items: start; }

@media (min-width: 1200px) {
  .action-2--normal {
    min-height: 350px;
    grid-column-start: span 3;
    grid-row-start: span 2; }
  .action-2--wide {
    min-height: 159px;
    grid-column-start: span 3; }
  .action-2--large {
    min-height: 458px;
    grid-column-start: span 4;
    grid-row-start: span 2; }
  .action-2--small {
    min-height: 213;
    grid-column-start: span 2; }
  .action-2--last {
    order: 1; } }

@media (max-width: 1199px) {
  .actions-grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px; }
  .action-2__bg {
    position: static; }
    .action-2__bg .bg__img {
      height: auto;
      position: static; } }

@media (max-width: 575px) {
  .action-2 {
    grid-column-start: span 2; }
  .actions-grid {
    grid-template-columns: 1fr 1fr; } }

.index-categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 32px;
  column-gap: 32px; }
  .index-categories__link {
    width: 192px;
    margin: calc(0.0125 * 100vw + 16px) auto 0; }
  .index-categories__to-all {
    margin-top: 17px; }
  .index-categories.show-all::before {
    display: none; }

@media (max-width: 1199px) {
  .index-categories {
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px; }
    .index-categories__to-all {
      display: none; } }

@media (min-width: 576px) {
  .index-categories__show-all {
    display: none; } }

@media (max-width: 575px) {
  .index-categories {
    grid-template-columns: 1fr; }
    .index-categories:not(.show-all) .category-block-2:nth-child(n+5) {
      display: none; }
    .index-categories:not(.show-all) .category-block-3:nth-child(n+5) {
      display: none; } }

.go-to-all {
  font-size: 16px;
  line-height: 1;
  color: var(--main); }
  .go-to-all__arrow {
    width: 13px;
    height: 8px;
    margin-left: 10px;
    fill: var(--main); }
  .go-to-all:hover {
    color: var(--main-darker); }
    .go-to-all:hover .go-to-all__arrow {
      fill: var(--main-darker); }

.tabs {
  position: relative; }
  .tabs__btn {
    padding-bottom: 19px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    cursor: pointer; }
    .tabs__btn:not(:last-child) {
      margin-right: 61px; }
    @media (max-width: 1919px) {
      .tabs__btn {
        font-size: calc(0.00438 * 100vw + 11.6px);
        line-height: calc(0.00562 * 100vw + 15.2px); } }
  .tabs__bar {
    width: 100px;
    height: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--main);
    border-radius: 2px;
    transform-origin: left top;
    transition: transform 0.7s; }
  .tabs-wrap {
    border-bottom: 1px solid #EAEAEA; }

.tab:not(.active) {
  display: none; }

.block-tab {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px; }

.tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  padding: 7 18px;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  margin: 0 6px;
  margin-bottom: 10px;
  transition: border ease 400ms; }
  .tab-item:hover {
    border-color: var(--main); }
    .tab-item:hover .tab-item__title {
      color: var(--main); }
  .tab-item_single .tab-item__title {
    margin-right: 0px; }
  .tab-item_active {
    border-color: var(--main);
    background-color: var(--main); }
    .tab-item_active .tab-item__title {
      color: #fff !important; }
  .tab-item_more {
    visibility: hidden;
    background-color: #EAEAEA;
    border: 1px solid #929292; }
    .tab-item_more .tab-item__title {
      color: #929292; }
    .tab-item_more.active {
      visibility: visible; }
  .tab-item__title {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin-right: 8px;
    transition: color ease 400ms; }
  .tab-item__count {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #B6B6B6; }

.block-tab-sort {
  display: flex;
  height: 32px; }
  .block-tab-sort__title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    transition: all ease 400ms;
    cursor: pointer;
    color: #929292; }
    .block-tab-sort__title-svg {
      width: 10px;
      height: 6px;
      margin-left: 9px;
      fill: #929292;
      transition: all ease 400ms; }
      @media (min-width: 992px) {
        .block-tab-sort__title-svg {
          display: none; } }
    .block-tab-sort__title_active svg {
      transform: rotate(180deg); }
    @media (min-width: 992px) {
      .block-tab-sort__title {
        pointer-events: none;
        font-size: calc(0.00215 * 100vw + 10.86652px);
        margin-right: calc(0.01184 * 100vw + -1.73412px); } }
  .block-tab-sort__grid {
    display: flex; }
  .block-tab-sort__filter {
    display: flex;
    margin-bottom: 17px; }
    @media (min-width: 992px) {
      .block-tab-sort__filter {
        display: none; } }
    .block-tab-sort__filter > *:first-child {
      margin-right: 20px; }
    .block-tab-sort__filter-btn {
      min-width: 157px;
      height: 54px; }
  .block-tab-sort__count {
    margin: auto 0;
    font-style: normal;
    font-weight: bold;
    color: #B6B6B6;
    font-size: 14px;
    line-height: 20px; }

.tab-item-sort {
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0 calc(0.00313 * 100vw + 7px);
  transition: border ease 400ms; }
  .tab-item-sort:hover .tab-item-sort__title {
    color: var(--main); }
  .tab-item-sort_active {
    border: 1px solid var(--main);
    border-radius: 4px; }
    .tab-item-sort_active .tab-item-sort__title {
      color: var(--main); }
  .tab-item-sort__title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #B6B6B6;
    transition: color ease 400ms; }
    @media (min-width: 992px) {
      .tab-item-sort__title {
        font-size: calc(0.0043 * 100vw + 6.74194px); } }
  .tab-item-sort__input {
    display: none; }
  .tab-item-sort__svg {
    width: 12px;
    height: 8px;
    fill: #B6B6B6;
    margin-left: 5px; }

.tab-order {
  z-index: 1;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 15px 27px;
  cursor: pointer;
  transition: all ease 300ms; }
  .tab-order:hover {
    border-color: var(--main); }
  .tab-order__cup {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #F4F4F4; }
  .tab-order__input {
    display: none; }
    .tab-order__input:checked ~ .tab-order__cup {
      background-color: var(--main-lighter); }
    .tab-order__input:checked ~ .tab-order__title {
      color: var(--main); }
  .tab-order.active {
    background-color: #EBF7F6; }
  .tab-order.active .tab-order__title {
    color: var(--main); }
  .tab-order__info {
    display: flex;
    flex-direction: column; }
  .tab-order__title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px; }
  .tab-order__subtitle {
    color: #929292;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px; }

.tab-pay {
  position: relative;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 15px 0;
  padding-left: 35px;
  padding-right: 15px;
  cursor: pointer;
  transition: all ease 300ms; }
  @media (max-width: 575px) {
    .tab-pay {
      padding: 15px; } }
  .tab-pay:hover {
    border-color: var(--main); }
  .tab-pay__cup {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; }
  .tab-pay__input {
    display: none; }
    .tab-pay__input:checked ~ .tab-pay__cup {
      background-color: var(--main-lighter); }
    .tab-pay__input:checked ~ .tab-pay__title {
      color: var(--main); }
  .tab-pay.disable {
    pointer-events: none;
    background-color: #F4F4F4;
    border: none; }
  .tab-pay.disable .tab-pay__title {
    color: #C4C4C4; }
  .tab-pay.disable .tab-pay__subtitle {
    color: #C4C4C4; }
  .tab-pay__info {
    max-width: 245px; }
  .tab-pay__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px; }
  .tab-pay__wrap {
    display: flex;
    height: fit-content; }
  .tab-pay__icon {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%; }
    .tab-pay__icon:not(:last-child) {
      margin-right: 12px; }
  .tab-pay__title {
    display: block;
    margin-right: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: var(--main); }
  .tab-pay__subtitle {
    color: #929292;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }
