.soc-icon{
    display: block;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    $this: &;
    &__icon{
        fill: #ffffff;
        width: 100%;
        height: 100%;
    }
    &--gray{
        background: #4F4F4F;
    }
    &--main-lighter{
        background: var(--main-lighter);
        #{$this}__icon{
            fill: var(--main);
        }
    }
}