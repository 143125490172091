.go-to-all{
    font-size: 16px;
    line-height: 1;
    color: var(--main);
    $this: &;
    &__arrow{
        width: 13px;
        height: 8px;
        margin-left: 10px;
        fill: var(--main);
    }
    &:hover{
        color: var(--main-darker);
        #{$this}__arrow{
            fill: var(--main-darker);
        }
    }
}