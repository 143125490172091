.main-slider{

    $this:&;

    position: relative;
    border-radius: $radius;

    &_banner{

        max-width: 100%;
        // margin: 0 !important;

        .container{

            @include _Extra_large {

                padding: 0 !important;
                
            }

        }

        #{$this}{

            &__arrow{



            }

            &__wrapper-text{

                @include _Extra_large_ {

                    padding: 0 100px;
                    
                }

            }

            &__wrapper-arrow{

                display: flex;
                justify-content: space-between;

                position: absolute;

                transform: translateY(-50%) translateX(-50%);
                left: 50%;
                top: 50%;

                z-index: 10;

            }

            &__controls{

                @include _Extra_large_ {

                    margin-top: 25px;
                    
                }

            }
    
        }

    }

    &__container{
        background: #B6B6B6;
        border-radius: $radius;
    }
    &__slide{
        height: 500px;
        padding: 0 88px;
    }
    &__title{

        margin-bottom: 15px;
        font-size: 42px;
        line-height: 54px;
        
        font-weight: 700;

        @include _max {

            margin-bottom: calcFluid(12,15);
            font-size: calcFluid(27,42);
            line-height: calcFluid(33,54);
            
        }

    }
    &__text{
        
        font-size: 16px;
        line-height: 24px;

        @include _max {

            font-size: calcFluid(13,16);
            line-height: calcFluid(19,24);
            
        }

    }
    &__title,&__text{
        max-width: 470px;
    }
    &__link{
        margin-top: 28px;
    }
    &__arrow{
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        margin: auto;
        &--prev{
            left: 0;
        }
        &--next{
            right: 0;
        }
        &--static{
            position: static;
            margin: 0;
        }
    }
    &__pagination{
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        z-index: 1;
        &--static{
            position: static;
        }
    }
    &__controls{
        &--offset{
            margin-top: 9px;
        }
    }
    &--white{
        color: #ffffff;
    }
}
.slider-arrow{
    width: 30px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: $radius;
    transition: box-shadow $tr-time, background-color $tr-time;
    cursor: pointer;
    svg{
        width: 12px;
        height: 8px;
        transform: rotate(90deg);
        fill: $text;
    }
    &--next{
        order: 1;
        svg{
            transform: rotate(-90deg);
        }
    }
    &--small{
        height: 42px;
    }
    &:hover{
        background: var(--main);
        box-shadow: none;
        svg{
            fill: #fff;
        }
    }
}
.slider-pagination{
    .swiper-pagination-bullet{
        width: 8px;
        height: 8px;
        margin: 0 4px;
        background: $border;
        border-radius: 50%;
        transition: background-color $tr-time;
        &-active{
            background: var(--main);
        }
    }
}

@media (min-width: 1200px){
    .main-slider{
        &--border{
            border: 1px solid #B6B6B6;
        }
    }
}
@media (max-width: 1199px){
    .main-slider{
        &__slide{
            height: calcFluid(250,500);
            padding: 0 35px;
        }
        &__arrow{
            display: none;
        }
        &__pagination{
            position: static;
        }
        &__controls{
            margin-top: 18px;
            justify-content: center;
        }
    }
}
@media (max-width: 767px){
    .main-slider{
        &__slide{
            padding: 20px;
            justify-content: flex-start;
        }
    }
}

@media (max-width: 567px){
    .main-slider{
        &__slide{
            height: auto;
        }
    }
}