@mixin font-h1{
	font-weight: 700;
	font-size: calcFluid(28,45);
	line-height: calcFluid(32,52);

}
@mixin font-h2{
	font-weight: 700;
	font-size: calcFluid(24,36);
	line-height: calcFluid(28,42);
}
@mixin font-h3{
	font-weight: 700;
	font-size: calcFluid(18,24);
	line-height: calcFluid(22,30);
}
@mixin font-h4 {
	font-weight: 700;
	font-size: calcFluid(18,20);
	line-height: calcFluid(24,26);
}
@mixin font-p{
	font-size: calcFluid(14,16);
	line-height: calcFluid(18,24);
}


// Адаптив
@mixin _max {
	@media (max-width: 1919px) {

		@content;

	}
}

@mixin _max_ {
	@media (min-width: 1920px) {

		@content;

	}
}

@mixin _container {
	@media (max-width: $containerWidth - 1px) {

		@content;

	}
}

@mixin _container_ {
	@media (min-width: $containerWidth) {

		@content;

	}
}

@mixin _Extra_large {
	@media (max-width: 1199px) {

		@content;

	}
}

@mixin _Extra_large_ {
	@media (min-width: 1200px) {
		
		@content;

	}
}


@mixin _large {
	@media (max-width: 991px) {

		@content;

	}
}

@mixin _large_ {
	@media (min-width: 992px) {

		@content;

	}
}


@mixin _medium {
	@media (max-width: 767px) {

		@content;

	}
}

@mixin _medium_ {
	@media (min-width: 768px) {

		@content;

	}
}


@mixin _small {
	@media (max-width: 575px) {

		@content;

	}
}

@mixin _small_ {
	@media (min-width: 576px) {

		@content;

	}
}