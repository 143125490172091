.category-block{
    border: 1px solid $border;
    border-radius: $radius;
    overflow: hidden;
    &__img{
        object-fit: scale-down;
    }
}
.category-block-1{
    min-height: 213px;
    position: relative;
    &__title{
        position: relative;
        padding: 0 calcFluid(7,10) calcFluid(8,15);
        font-size: calcFluid(10,15);
        line-height: calcFluid(13,22);
        font-weight: 700;
        text-align: center;
    }
    &__img{
        width: 138px;
        height: 138px;
    }
    &::before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--main);
        opacity: 0;
        transition: opacity $tr-time;
    }
    &:hover{
        color: #ffffff;
        &::before{
            opacity: 0.8;
        }
    }
}
.category-block-2{
    min-height: 115px;
    padding: 23px 27px;
    position: relative;
    transition: border-color $tr-time, box-shadow $tr-time;
    &__title{
        max-width: 60%;
        font-size: 15px;
        line-height: 20px;
    }
    &__img{
        width: 105px;
        height: 105px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 13px;
        margin: auto;
    }
    &:hover{
        border-color: transparent;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    }
}

.category-block-3{

    $this:&;

    display: flex;
    flex-direction: column;

    min-height: 320px;

    &__header{

        position: relative;
        height: 130px;

        padding: 30px;

    }

    &__rect{

        transition: all ease-in-out $tr-time;

        position: absolute;

        z-index: -1;

        width: 30px;
        height: 12px;

        left: 0;
        top: 0;

        background-color: var(--main);

    }

    &__title{

        transition: all ease $tr-time;

        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;

    }

    &__img{

        display: block;
        
        object-fit: contain;
        object-position: center;
        
        width: 100%;
        height: 100%;
        
        max-width: 100%;
        max-height: 100%;

        &-wrap{

            margin-left: auto;

            max-width: 210px;
            max-height: 180px;

        }

    }

    &__wrapper-image{

        display: flex;
        flex-grow: 1;        
        
        padding: 10px;
    }
    
    &:hover{

        #{$this}{

            &__title{

                color: #fff;

            }

            &__rect{

                width: 100%;
                height: 100%;

            }
    
        }
        
        border-color: transparent;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

    }

}

@media (max-width: 991px){
    .category-block-1{
        min-height: 113px;
        &__img{
            width: 75px;
            height: 60px;
        }
    }
    .category-block-2{
        min-height: 94px;
        display: flex;
        align-items: center;
        &__img{
            width: 73px;
            height: 73px;
        }
    }
}
@media (max-width: 575px){

}