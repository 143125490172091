.header-3{
    &__city{
        margin-left: 57px;
    }
    &__menu{
        padding-left: 58px;
        justify-content: flex-end;
        
        & > * {

            &:not(:last-child){

                margin-right: 30px;

            }

        }

    }
    &__actions{
        padding: 0 20px 0 45px;
    }
    &__contacts{
        padding-left: 10px;
    }
}