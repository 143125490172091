$t: .4s;
.burger{
	position: relative;
	cursor: pointer;
	$this: &;
	&__line{
		display: block;
		width: 100%;
		height: 2px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		&--cross{
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	&--opened{
		#{$this}__line{
			&:first-child, &:last-child{
				opacity: 0;
				transition: transform $t, opacity 0s $t;
			}
			&:first-child,&:last-child{
				transform: none;
			}
			&:nth-child(2){
				transform: rotate(45deg);
			}
			&:nth-child(3){
				transform: rotate(-45deg);
			}
			&--cross{
				transition: transform $t $t;
			}
		}
	}
	&--closed{
		#{$this}__line{
			&--cross{
				transition: transform $t;
			}
			&:first-child, &:last-child{
				transition: transform $t $t, opacity 0s $t;
			}
		}
	}
}